// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    tranform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    tranform: rotate(360deg);
  }
}
.spinner {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 111;
}
.spinner .ant-spin-dot {
  top: 45%;
  width: 65px;
  height: 65px;
  animation: spin 3s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/spinner/styles.scss"],"names":[],"mappings":"AAEA;EACE;IACE,+BAAA;IACA,sBAAA;EADF;EAGA;IACE,iCAAA;IACA,wBAAA;EADF;AACF;AAIA;EACE,eAAA;EACA,oCAAA;EAGA,WAAA;EACA,YAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;AAJF;AAKE;EACE,QAAA;EACA,WAAA;EACA,YAAA;EAEA,kCAAA;AAHJ","sourcesContent":["@import \"src/assets/scss/variable.scss\";\r\n\r\n@keyframes spin {\r\n  0% {\r\n    -webkit-transform: rotate(0deg);\r\n    tranform: rotate(0deg);\r\n  }\r\n  100% {\r\n    -webkit-transform: rotate(360deg);\r\n    tranform: rotate(360deg);\r\n  }\r\n}\r\n\r\n.spinner {\r\n  position: fixed;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  // background-color: $color-nav-black;\r\n  // opacity: 0.9;\r\n  width: 100%;\r\n  height: 100%;\r\n  top: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  z-index: 111;\r\n  .ant-spin-dot {\r\n    top: 45%;\r\n    width: 65px;\r\n    height: 65px;\r\n    -webkit-animation: spin 3s linear infinite;\r\n    animation: spin 3s linear infinite;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
