import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useRouteMatch } from "react-router-dom";
import {
  AvatarIcon,
  LogoMenu,
  MenuCloseIcon,
  MenuFlagIcon,
  MenuLogoutDesktop,
  MenuLogoutIcon,
  MenuScanQrIcon,
  MenuSettingIcon,
  ScanCameraIcon,
  ScanGenerateIcon,
  ScanImageIcon,
  TierhubLogo,
} from "src/assets/icons";
import {
  hasStorageJwtToken,
  removeStorageJwtToken,
  removeStorageRefreshToken,
  removeStorageRememberMe,
} from "src/helpers/storage";
// import { fetchListUnread } from "src/store/reducers/notification";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { TIER, USER_STATUS } from "src/constants/login";
import { PATHS } from "src/constants/paths";
import useWindowSize from "src/hooks/useWindowSize";
import { messaging, removeTokenFirebase } from "src/notifications/firebase";
import { UserServices } from "src/services/user-service";
import { setTheme } from "src/store/actions/theme";
import {
  setAccessTokenRedux,
  setLogout,
  setOwner,
  setOwnerID,
  setProfileStatus,
  setStoreGroupId,
  setStoreMyTier,
  setUserAvatarRedux,
  setUserEmailRedux,
  setUserIdRedux,
  setUserNameRedux,
} from "src/store/actions/user";
import { UserState } from "src/store/reducers/user";
import { ECountry } from "src/types/country";
import {
  MENUS_ITEM,
  MENUS_KEY,
  MenuItemsForAnonymouseUser,
  MenuItemsForTier1,
  MenuItemsForTier1Employee,
  MenuItemsForTier245Employee,
  MenuItemsForTier245Owner,
  MenuItemsForTier3Employee,
  MenuItemsForTier3Owner,
  MenuItemsForTier6,
} from "../../constants/sidebar";
import TierTooltip from "../04.tooltip";
import ButtonContained from "../08.buttons/ButtonContained";
import ButtonOutLined from "../08.buttons/ButtonOutLined";
import DefaultFooter from "../21.default-footer";
import DefaultHeader from "../22.default-header";
import SearchGlobal from "./search-global";
import "./styles.scss";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/es/upload";
import useQRImageScanner from "src/hooks/useQRImageScanner";
import { productManagementService } from "src/services/product-management";
import { toastMS } from "../28.toast-message";
import { qrCodesService } from "src/services/qr-code-service";
import { EQRCodeType } from "src/constants";
import { NotificationServices } from "src/services/notification-service";
import NotificationDropdown from "./notification-dropdown";
import { getToken } from "firebase/messaging";
import { Spinner } from "../spinner";

const { Header, Content } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

const headerStyle: React.CSSProperties = {
  padding: "0px",
  // boxShadow: "0px -1px 0px 0px #EBEBEB inset",
  background: "transparent",
  height: "fit-content",
  lineHeight: "unset",
  position: "sticky",
  top: 0,
  zIndex: 100,
};

const USER_NAME_LIMIT = 14;
const USER_NAME_LIMIT_MOBILE = 25;

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const { isMobileAndTablet } = useWindowSize();
  const history = useHistory();
  const location = useLocation();
  const locationState = location?.state as any;
  const user: UserState = useSelector((state: any) => state.user);
  const userService = new UserServices();
  const notificationServices = new NotificationServices();
  const { decodeQRFromUploadedFile } = useQRImageScanner();
  const path = location.pathname.split("/")[1];
  const countryParam = new URLSearchParams(window.location.search).get("country");
  const dispatch = useDispatch();
  const isProductCategoryViewPage = useRouteMatch(PATHS.productCategoryView());
  const [collapsed] = useState(false);
  const isLogined = hasStorageJwtToken();
  dispatch(setTheme(collapsed));

  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const isDefaultPage = () => {
    switch (location.pathname) {
      case PATHS.default():
        return true;
      case PATHS.productCategory():
        return true;
      case PATHS.listQRCode():
        return false;
      case PATHS.internalManagement():
        return false;
      case PATHS.createMember():
        return false;
      case PATHS.logisticManagement():
        return false;
      default:
        return true;
    }
  };
  const pathSnippets =
    location.pathname !== "/" ? location.pathname.split("/").filter((i) => i) : [""];

  const breadcrumbItems = pathSnippets?.map((snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const title = snippet.replace(/-/g, " ");
    const isLastItem = index === pathSnippets.length - 1;

    return (
      <Breadcrumb.Item className="breadcrumb" key={url}>
        {isLastItem && locationState?.name ? (
          locationState.name
        ) : (
          <NavLink to={url}>{title}</NavLink>
        )}
      </Breadcrumb.Item>
    );
  });

  const pageTitle = () => {
    let title = pathSnippets.slice(-1)[0].replace(/-/g, " ");
    return locationState?.name || startCase(title);
  };

  const getProfile = async () => {
    try {
      const response = await userService.getUserProfile();
      if (response.data) {
        const { firstName, surname } = response?.data || {};
        const fullName = `${firstName || ""} ${surname || ""}`;
        const isOwner = response.data?.group?.ownerId === response.data.id;
        dispatch(setOwner(isOwner));
        dispatch(setStoreMyTier(response.data?.tierType));
        dispatch(setOwnerID(response.data?.group?.ownerId));
        dispatch(setStoreGroupId(response.data?.group?.id));
        dispatch(setAccessTokenRedux(response.data?.email));
        dispatch(setProfileStatus(response.data?.status));
        dispatch(setUserEmailRedux(response.data?.email));
        dispatch(setUserAvatarRedux(response.data?.avatar));
        dispatch(setUserNameRedux(fullName));
        dispatch(setUserIdRedux(response.data?.id));
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const isAnonymousUser = !hasStorageJwtToken() || user.status !== USER_STATUS.APPROVED;

  const onScanImage = async (info: UploadChangeParam<UploadFile<any>>) => {
    try {
      if (
        info?.file.originFileObj &&
        (info.file.status === "done" || info.file.status === "error")
      ) {
        const decodedQR = await decodeQRFromUploadedFile(info?.file.originFileObj);
        if (decodedQR) {
          let QR_URL: string;
          if (decodedQR.includes("/")) {
            const segments = decodedQR.split("/");
            const productId = segments[segments.length - 1];
            QR_URL = productId;
          } else {
            QR_URL = decodedQR;
          }
          if (user?.myTier === TIER.CUSTOMER) {
            await productManagementService.getProductByQrCode(QR_URL, { isScan: true });
            history.push(`${PATHS.productView()}/${QR_URL}`);
            return;
          }
          if (isLogined) history.push(`${PATHS.productManagement()}/${QR_URL}`);
          else history.push(`${PATHS.productView()}/${QR_URL}`);
        } else {
          toastMS("error", t("msg.noScanResultsFromUploadedImage"));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const goToThePage = (page: string) => {
    if (page.includes("/")) {
      return;
    }
    history.push(`/${page}`);
    setIsMenuMobileOpen(false);
  };

  const SIDEBAR_MENUS_USER: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.HOME.icon,
      label: t(MENUS_ITEM.HOME.label),
      key: MENUS_ITEM.HOME.key,
      onClick: () => goToThePage(MENUS_KEY.HOME),
    },
    {
      icon: MENUS_ITEM.MAP_AU.icon,
      label: t(MENUS_ITEM.MAP_AU.label),
      key: MENUS_ITEM.MAP_AU.key,
      onClick: () => history.push(PATHS.map() + "?country=" + ECountry.AU),
    },
    {
      icon: MENUS_ITEM.MAP_HK.icon,
      label: t(MENUS_ITEM.MAP_HK.label),
      key: MENUS_ITEM.MAP_HK.key,
      onClick: () => history.push(PATHS.map() + "?country=" + ECountry.HK),
    },
    {
      icon: MENUS_ITEM.PRODUCT_CATEGORY.icon,
      label: t(MENUS_ITEM.PRODUCT_CATEGORY.label),
      key: MENUS_ITEM.PRODUCT_CATEGORY.key,
      onClick: () => goToThePage(MENUS_KEY.PRODUCT_CATEGORY),
    },
    {
      icon: MENUS_ITEM.QR_CODE_LIST.icon,
      label: t(MENUS_ITEM.QR_CODE_LIST.label),
      key: MENUS_ITEM.QR_CODE_LIST.key,
      onClick: () => goToThePage(MENUS_KEY.QR_CODE_LIST),
    },
    {
      icon: MENUS_ITEM.INTERNAL_MANAGEMENT.icon,
      label: t(MENUS_ITEM.INTERNAL_MANAGEMENT.label),
      key: MENUS_ITEM.INTERNAL_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.INTERNAL_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.LOGISTIC_MANAGEMENT.icon,
      label: t(MENUS_ITEM.LOGISTIC_MANAGEMENT.label),
      key: MENUS_ITEM.LOGISTIC_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.LOGISTIC_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.SUBSCRIPTION.icon,
      label: t(MENUS_ITEM.SUBSCRIPTION.label),
      key: MENUS_ITEM.SUBSCRIPTION.key,
      onClick: () => goToThePage(MENUS_KEY.SUBSCRIPTION),
    },
  ];

  const SIDEBAR_USER_SETTINGS = [
    ...(isMenuMobileOpen && !isAnonymousUser
      ? [
          {
            icon: MENUS_ITEM.SETTING.icon,
            label: t(MENUS_ITEM.SETTING.label),
            key: MENUS_ITEM.SETTING.key,
            onClick: () => goToThePage(MENUS_KEY.SETTING),
          },
          ...(user.myTier === TIER.CUSTOMER
            ? [
                {
                  icon: MENUS_ITEM.ADVENTURE.icon,
                  label: t(MENUS_ITEM.ADVENTURE.label),
                  key: MENUS_ITEM.ADVENTURE.key,
                  onClick: () => goToThePage(MENUS_KEY.ADVENTURE),
                },
              ]
            : []),
        ]
      : []),
  ];

  const sidebarItemByTier: ItemType[] = SIDEBAR_MENUS_USER.filter((item) => {
    if (!isAnonymousUser && user.myTier) {
      switch (user.myTier) {
        case TIER.HARVESTER:
          if (user.isOwner) {
            return MenuItemsForTier1.includes(item?.key as string);
          } else {
            return MenuItemsForTier1Employee.includes(item?.key as string);
          }
        case TIER.LOGISTIC_PARTNER:
        case TIER.DISTRIBUTOR:
          if (user.isOwner) {
            return MenuItemsForTier245Owner.includes(item?.key as string);
          } else {
            return MenuItemsForTier245Employee.includes(item?.key as string);
          }
        case TIER.FACTORY:
          if (user.isOwner) {
            return MenuItemsForTier3Owner.includes(item?.key as string);
          } else {
            return MenuItemsForTier3Employee.includes(item?.key as string);
          }
        case TIER.CUSTOMER:
          return MenuItemsForTier6.includes(item?.key as string);
        default:
          return item;
      }
    } else {
      return MenuItemsForAnonymouseUser.includes(item?.key as string);
    }
  });

  const handleUnsubcribeTopic = async () => {
    try {
      const permission = Notification.permission;
      if (permission === "granted" && messaging) {
        setLogoutLoading(true);
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        if (token) await notificationServices.unsubcribeTopic(token);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const logout = async () => {
    await handleUnsubcribeTopic();
    removeTokenFirebase();
    removeStorageJwtToken();
    removeStorageRefreshToken();
    removeStorageRememberMe();
    dispatch(setLogout());
    setIsMenuMobileOpen(false);
    window.sessionStorage.clear();
    history.push(PATHS.login());
  };

  const signIn = () => {
    removeTokenFirebase();
    removeStorageJwtToken();
    removeStorageRefreshToken();
    removeStorageRememberMe();
    dispatch(setLogout());
    setIsMenuMobileOpen(false);
    window.sessionStorage.clear();
    history.push(PATHS.login());
  };

  const scanOption = [
    {
      title: "Scan QR Code",
      icon: ScanCameraIcon,
      disabledTier: [],
      onClick: () => history.push(PATHS.qrScan()),
    },
    {
      title: "Generate New QR Code",
      icon: ScanGenerateIcon,
      disabledTier: [TIER.LOGISTIC_PARTNER, TIER.DISTRIBUTOR, TIER.CUSTOMER],
      onClick: async () => {
        try {
          const res = await qrCodesService.createQrCodes({
            numberOfQrCodes: 1,
            type: EQRCodeType.LOT,
          });
          if (Array.isArray(res)) {
            history.push(`${PATHS.productManagement()}/${res[0]}`);
            toastMS("success", t("msg.qrCodeCreated"));
          }
        } catch (error) {
          toastMS("error", "Create Qr Code failed");
        }
      },
    },
    {
      type: "upload",
      title: t("scan.scanQRCodeFromImage"),
      icon: ScanImageIcon,
      disabledTier: [],
      onClick: () => {},
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "0",
      type: "group",
      label: (
        <TierTooltip title={user.name?.length > USER_NAME_LIMIT ? user.name : ""}>
          <h4>{user.name}</h4>
        </TierTooltip>
      ),

      children: [],
    },
    {
      key: "1",
      label: <Link to={PATHS.setting()}>{t("header.setting")}</Link>,
      icon: <MenuSettingIcon />,
    },
    user.myTier === TIER.CUSTOMER
      ? {
          key: "2",
          label: <Link to={PATHS.yourAdventure()}>{t("header.yourAdventure")}</Link>,
          icon: <MenuFlagIcon />,
        }
      : null,
    {
      key: "3",
      label: (
        <Link style={{ color: "red" }} to={PATHS.login()} onClick={logout}>
          {t("header.logout")}
        </Link>
      ),
      icon: <MenuLogoutDesktop />,
    },
  ];

  // const onSearchProductName = (e: any) => {
  //   dispatch(setSearchProductName(e.target.value));
  // };

  const pathLayoutEmpty = () => {
    const paths = [
      PATHS.qrScan(),
      PATHS.productManagement(),
      PATHS.registration(),
      PATHS.login(),
      PATHS.passwordChanged(),
      PATHS.setting(),
      PATHS.personalInfo(),
      PATHS.password(),
      PATHS.payment(),
      PATHS.notificationSettings(),
      PATHS.productView(),
      PATHS.detailTracking(),
      // PATHS.updateProduct(),
    ];
    const isLayoutEmpty = paths.some((url) => location.pathname.includes(url));
    return isLayoutEmpty;
  };

  const handleOpenMobileMenu = () => {
    setIsMenuMobileOpen(true);
  };

  // const isShowSearchInput = isProductCategoryViewPage;

  const isShowQrScanButton = true;
  // location.pathname === PATHS.default() ||
  // location.pathname === PATHS.productCategory() ||
  // isProductCategoryViewPage ||
  // location.pathname === PATHS.yourAdventure();

  useEffect(() => {
    if (hasStorageJwtToken() === true) {
      getProfile();
    }
    // console.log('hasStorageJwtToken()',hasStorageJwtToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStorageJwtToken(), user.status]);

  return (
    <>
      <div id="layout">
        {logoutLoading && <Spinner />}
        {pathLayoutEmpty() ? (
          <>{children}</>
        ) : (
          <Layout style={{ minHeight: "100vh" }}>
            {isMobileAndTablet ? (
              <>
                {/* {!isProductCategoryViewPage && ( */}
                <DefaultHeader
                  isShowMenuButton={true}
                  handleMenuButton={handleOpenMobileMenu}
                  isAnonymous={isAnonymousUser}
                  handleScanButton={() => history.push(PATHS.qrScan())}
                />
                {/* )} */}
                <Drawer
                  placement={"right"}
                  width={335}
                  onClose={() => setIsMenuMobileOpen(false)}
                  open={isMenuMobileOpen}
                  title={
                    <div
                      className="menu-mobile__logo"
                      onClick={() => history.push(PATHS.default())}
                    >
                      <img src={TierhubLogo} alt="logo" />
                    </div>
                  }
                  closeIcon={<img src={MenuCloseIcon} alt="logo-close" />}
                  className={`menu-mobile ${
                    user.myTier ? (user.myTier === TIER.CUSTOMER ? "tier6" : "other-tier") : ""
                  }`}
                  zIndex={1001}
                >
                  {!isAnonymousUser ? (
                    <div className="user-setting">
                      <img src={user?.avatar || AvatarIcon} alt="" className="user-avatar" />

                      <TierTooltip
                        title={user.name?.length > USER_NAME_LIMIT_MOBILE ? user.name : ""}
                      >
                        {" "}
                        <div className="user-name">{user.name}</div>{" "}
                      </TierTooltip>
                    </div>
                  ) : null}
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[MENUS_KEY.HOME]}
                    defaultOpenKeys={[]}
                    // style={{ height: "100%", borderRight: 0 }}
                    items={[...sidebarItemByTier, ...SIDEBAR_USER_SETTINGS]}
                    selectedKeys={[path]}
                    inlineCollapsed={collapsed}
                  />
                  {isAnonymousUser ? (
                    <div className="menu-mobile__button">
                      <ButtonContained onClick={signIn}>{t("header.login")}</ButtonContained>
                    </div>
                  ) : (
                    <div className="menu-mobile__button">
                      <ButtonOutLined onClick={logout}>
                        <MenuLogoutIcon />
                        {t("header.logout")}
                        <span></span>
                      </ButtonOutLined>
                    </div>
                  )}
                </Drawer>
              </>
            ) : (
              <></>
            )}
            <Layout>
              {!isMobileAndTablet ? (
                <Header style={headerStyle}>
                  {!isAnonymousUser ? (
                    <>
                      <div className="header-wrapper">
                        <Row gutter={[16, 24]} align="middle" justify="space-between">
                          <Col span={8}>
                            <div className="header-actions">
                              <img
                                src={LogoMenu}
                                alt="login-background"
                                className="header-logo"
                                onClick={() => history.push(PATHS.default())}
                              />
                              {/* <InputSearch
                                // disabled={!isShowSearchInput}
                                placeholder={t("header.placeholderProductName")}
                                handleChangeSearch={onSearchProductName}
                                debounceTime={800}
                                enterButton={
                                  <SearchOutlined style={{ width: "15px", height: "15px" }} />
                                }
                              /> */}
                              <SearchGlobal />
                            </div>
                          </Col>

                          <Col span={4} style={{ textAlign: "right" }}>
                            <div className="user-setting">
                              {isShowQrScanButton && (
                                <Dropdown
                                  overlayClassName="scan-option-dropdown"
                                  dropdownRender={() => (
                                    <div className="scan-option-list">
                                      {scanOption.map((item) => {
                                        if ((item.disabledTier as any).includes(user.myTier))
                                          return null;

                                        const optionContent = (
                                          <div className="scan-option" onClick={item.onClick}>
                                            <img
                                              src={item.icon}
                                              alt="scan icon"
                                              className="scan-icon"
                                            />
                                            <span className="scan-title">{item.title}</span>
                                          </div>
                                        );

                                        if (item?.type !== "upload") return optionContent;
                                        else
                                          return (
                                            <Upload onChange={onScanImage} showUploadList={false}>
                                              {optionContent}
                                            </Upload>
                                          );
                                      })}
                                    </div>
                                  )}
                                >
                                  <MenuScanQrIcon />
                                </Dropdown>
                              )}
                              <Dropdown overlayClassName="dropdown-menu" menu={{ items }} arrow>
                                <img
                                  src={user?.avatar || AvatarIcon}
                                  alt=""
                                  className="user-avatar"
                                />
                              </Dropdown>
                              <div className="header-divider"></div>
                              <NotificationDropdown />
                            </div>
                          </Col>
                        </Row>
                        <Row align="middle" justify="start" className="header-menu">
                          {sidebarItemByTier.map((item: any) => (
                            <Col
                              className={`menu-item ${
                                item.key.includes(path) || countryParam === item.key ? "active" : ""
                              }`}
                              onClick={item?.onClick}
                            >
                              {item?.label}
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  ) : (
                    <div className="header-wrapper">
                      <Row justify="space-between" align="middle">
                        <Col span={6}>
                          <div className="header-actions">
                            <img
                              src={LogoMenu}
                              alt="login-background"
                              className="header-logo"
                              onClick={() => history.push(PATHS.default())}
                            />
                            {/* <h2 className="text-welcome">{t("header.welcome")}</h2> */}
                            <SearchGlobal />
                          </div>
                        </Col>
                        <Col span={4} style={{ textAlign: "right" }}>
                          <Space className="user-setting">
                            {isShowQrScanButton && (
                              <Button
                                className="scan-btn"
                                type="text"
                                icon={<MenuScanQrIcon />}
                                onClick={() => history.push(PATHS.qrScan())}
                              />
                            )}
                            <ButtonOutLined style={{ color: "white" }} onClick={signIn}>
                              {t("header.signin")}
                            </ButtonOutLined>
                          </Space>
                        </Col>
                      </Row>
                      <Row align="middle" justify="start" className="header-menu">
                        {sidebarItemByTier.map((item: any) => (
                          <Col
                            className={`menu-item ${
                              item.key.includes(path) || countryParam === item.key ? "active" : ""
                            }`}
                            onClick={item?.onClick}
                          >
                            {item?.label}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </Header>
              ) : null}

              <Content
                className={`custom-layout-content ${
                  PATHS.default().includes(path) || PATHS.map().includes(path) ? "p0" : ""
                }  ${isDefaultPage() ? "homepage-layout" : ""}`}
              >
                {isMobileAndTablet && !isDefaultPage() ? (
                  <h2 className="header-title">{pageTitle()}</h2>
                ) : null}

                {!isDefaultPage() && !isAnonymousUser && !isMobileAndTablet ? (
                  <Breadcrumb style={{ margin: "16px 0" }} className="breadcrumb-container">
                    <Breadcrumb.Item className="breadcrumb" onClick={() => history.push("/home")}>
                      {t("tab.home")}
                    </Breadcrumb.Item>
                    {breadcrumbItems}
                  </Breadcrumb>
                ) : null}
                {children}
              </Content>
              <DefaultFooter />
            </Layout>
          </Layout>
        )}
      </div>
    </>
  );
};

export default LayoutDefault;
