// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #dd8430;
  border-color: #dd8430;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #dd8430;
}

.ant-checkbox-checked::after {
  border: 1px solid #dd8430 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/09.checkbox/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,qBAAA;AACF;;AAEA;;;EAGE,qBAAA;AACF;;AAEA;EACE,oCAAA;AACF","sourcesContent":[".ant-checkbox-checked .ant-checkbox-inner {\n  background-color: #dd8430;\n  border-color: #dd8430;\n}\n\n.ant-checkbox-wrapper:hover .ant-checkbox-inner,\n.ant-checkbox:hover .ant-checkbox-inner,\n.ant-checkbox-input:focus + .ant-checkbox-inner {\n  border-color: #dd8430;\n}\n\n.ant-checkbox-checked::after {\n  border: 1px solid #dd8430 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
