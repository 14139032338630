import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { CategoryService } from "src/services/category-service";
import { CountryServices } from "src/services/country-service";
import { ICategory } from "src/types/categories";
import { ECountry } from "src/types/country";

const useFetchCategoriesForFilter = (selectedCountry: ECountry) => {
  const categoryService = new CategoryService();
  const countryService = new CountryServices();

  const [searchSubcategoryValue, setSearchSubcategoryValue] = useState<string>("");

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const { data: subCategories } = useQuery({
    queryKey: ["sub-categories", selectedCountry],
    queryFn: () =>
      categoryService.getCategories({
        hasParent: true,
        isShowed: true,
        isAuMap: selectedCountry === ECountry.AU ? true : undefined,
        isHKMap: selectedCountry === ECountry.HK ? true : undefined,
        direction: "ASC",
        sortBy: "name",
      }),
    select(data) {
      return get(data, "data", []) as ICategory[];
    },
  });

  const { data: subCategoriesSuggestions } = useQuery({
    queryKey: ["sub-categories-suggestion", debouncedSearchValue],
    queryFn: () =>
      categoryService.getCategories({
        search: debouncedSearchValue,
        hasParent: true,
        isShowed: true,
        isAuMap: selectedCountry === ECountry.AU ? true : undefined,
        isHKMap: selectedCountry === ECountry.HK ? true : undefined,
      }),
    select(data) {
      return get(data, "data", []) as ICategory[];
    },
  });

  const { data: categories } = useQuery({
    queryKey: ["categories", selectedCountry],
    queryFn: () =>
      categoryService.getCategories({
        hasParent: false,
        isShowed: true,
        isAuMap: selectedCountry === ECountry.AU ? true : undefined,
        isHKMap: selectedCountry === ECountry.HK ? true : undefined,
        sortBy: "name",
        direction: "ASC",
      }),
    select(data) {
      return get(data, "data", []) as ICategory[];
    },
  });

  const { data: fishMarkets } = useQuery({
    queryKey: ["fish-markets", selectedCountry],
    queryFn: () =>
      countryService.getFishMarket({
        sortBy: "name",
        direction: "ASC",
      }),
    select(data) {
      return get(data, "data", []);
    },
  });

  const { data: regionOptions } = useQuery({
    queryKey: ["regions", selectedCountry],
    queryFn: () => countryService.getRegionsCountry(selectedCountry || ""),
    select(data) {
      return get(data, "data", []);
    },
  });

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchValue(searchSubcategoryValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchSubcategoryValue]);

  return {
    fishMarkets,
    categories,
    regionOptions,
    subCategories,
    searchSubcategoryValue,
    subCategoriesSuggestions,
    setSearchSubcategoryValue,
  };
};

export default useFetchCategoriesForFilter;
