import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isLogined = hasStorageJwtToken();
  let location = useLocation();
  const currentPath = encodeURIComponent(`${location.pathname}`);

  if (!isLogined) {
    return (
      <Redirect
        to={{
          pathname: `${PATHS.login()}`,
          search: `next=${currentPath}`,
          state: currentPath,
        }}
      />
    );
  }

  return <Route {...props} />;
};
