import { Button, ButtonProps } from "antd";
import React from "react";
import "./styles/button-outlined.scss";

interface IButtonOutLinedProps extends ButtonProps {
  className?: "outlined-remove" | "default-h40" | string;
}

const ButtonOutLined: React.FC<IButtonOutLinedProps> = ({
  children,
  className,
  ...props
}: IButtonOutLinedProps) => {
  return (
    <Button
      className={`outlined-default ${className || className} `}
      {...props}
      onMouseDown={(e) => e.preventDefault()}
    >
      {children}
    </Button>
  );
};

export default ButtonOutLined;
