import { UserProfileResponse } from "src/interfaces/user";
import { ServiceBase } from "./core/service-base";
import {
  DeleteNewFeedParams,
  UpdateBankDetail,
  UpdateBusinessDetail,
  UpdateIntroductionParams,
  UpdateLicenseDetail,
  UploadNewFeedParams,
} from "./params-type";

export interface UserInfo {
  firstName: string;
  surname: string;
  tierType?: number;
  avatar?: string;
  dateOfBirth?: string | null;
  gender: number;
  phoneNumber?: string;
  countryId?: number;
}
export class UserServices extends ServiceBase {
  deActivateAccount = async () => {
    const res = await this.put(`users/deactive`, {});
    return res;
  };

  getUserProfile = (): Promise<{ data: UserProfileResponse }> => {
    return this.get("/users/profile");
  };

  getGroupInfo = (groupId: string): Promise<{ data: Group }> => {
    return this.get("/groups/" + groupId);
  };

  getNewFeed = (groupId: string) => {
    return this.get("/groups/new-feed/" + groupId);
  };

  updateUser = (params: UserInfo) => {
    return this.put("/users", params);
  };

  updateBusinessDetail = (groupId: string, params: UpdateBusinessDetail) => {
    return this.put("/groups/update-business-detail/" + groupId, params);
  };

  updateBankDetail = (groupId: string, params: UpdateBankDetail) => {
    return this.put("/groups/update-bank-detail/" + groupId, params);
  };

  updateLicenseDetail = (groupId: string, params: UpdateLicenseDetail) => {
    return this.put("/groups/update-license-detail/" + groupId, params);
  };

  updateIntroduction = (groupId: string, params: UpdateIntroductionParams) => {
    return this.put("/groups/update-group-introduction/" + groupId, params);
  };

  uploadNewFeed = (params: UploadNewFeedParams) => {
    return this.post("/groups/new-feed", params);
  };

  deleteNewFeed = (params: DeleteNewFeedParams) => {
    return this.delete2("/groups/new-feed", params);
  };
}
