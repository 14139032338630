import { ServiceBase } from "./core/service-base";
import { AvailableFavoriteGroupParams, FavoriteGroupParams } from "./params-type";

export class GroupServices extends ServiceBase {
  registerBusinessGroup = async (params: any) => {
    return this.post("/groups/register-business-group", params);
  };

  getFavoriteGroups = async (params: FavoriteGroupParams): Promise<{ data: any[]; meta: any }> => {
    const res = await this.get("/favorite-groups", params);
    return res;
  };
  getGlobalGroups = async (params: any) => {
    const res = await this.get("/groups", params);
    return res;
  };

  addFavoriteGroup = async (id: number) => {
    const res = await this.post("/favorite-groups", {
      groupId: id,
    });
    return res.data;
  };

  deleteFavoriteGroup = async (ids: number[]) => {
    await this.delete2("/favorite-groups", { favoriteGroupIds: ids });
  };

  getAvailableFavoriteGroups = async (
    params?: AvailableFavoriteGroupParams,
  ): Promise<{ data: any[]; meta: any }> => {
    const res = await this.get("/favorite-groups/available-groups", params);
    return res;
  };

  getGroupInfo = async (groupId: any) => {
    const res = await this.get("/groups/" + groupId);
    return res.data;
  };

  getGroupMedias = async (groupId: any) => {
    const res = await this.get("/groups/group-media/" + groupId);
    return res.data;
  };

  getNewsFeed = async (groupId: any) => {
    const res = await this.get(`/groups/new-feed/${groupId}`);
    return res.data;
  };

  searchGroupHomepage = async (params: any) => {
    return this.get("/groups/homepage-search", params);
  };
}
