import { InfoWindow, Marker, OverlayView, OverlayViewF } from "@react-google-maps/api";
import { Image } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NoDataProduct, ProductPin } from "src/assets/icons";
import { PATHS } from "src/constants/paths";
import { CategoryLocation, SubCategoriesAtSamePosition } from "src/types/categories";
import { ECountry } from "src/types/country";
interface Props {
  marker: CategoryLocation;
  selectedMarkerId: number;
  setSelectedMarkerId: (name: number) => void;
  setListSubcategories?: (list: SubCategoriesAtSamePosition[]) => void;
  listSubcategories?: SubCategoriesAtSamePosition[];
  handleOpenSubcategoryListModal?: () => void;
}

const MarkerMap = (props: Props) => {
  const { t } = useTranslation();
  const countryParam = new URLSearchParams(window.location.search).get("country");
  const {
    marker,
    selectedMarkerId,
    setSelectedMarkerId,
    setListSubcategories: setAllSubcategories,
    listSubcategories,
    handleOpenSubcategoryListModal,
  } = props;
  const history = useHistory();
  const markerRef = useRef<HTMLDivElement | null>(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const showInfoWindow = (markerId: number) => {
    setSelectedMarkerId(markerId);
    setInfoWindowOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (markerRef.current && !markerRef.current.contains(event.target as Node)) {
        if (event.target instanceof Element && event.target.tagName.toLowerCase() !== "img") {
          setInfoWindowOpen(false);
        }
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleShowMarkerModal = async () => {
    showInfoWindow(marker.id);
    setAllSubcategories?.(listSubcategories || []);
  };

  const handleNavigateSubcategory = () => {
    history.push(`${PATHS.subcategoryDetail()}/${marker.categoryId}`);
  };

  return (
    <Marker
      clickable={true}
      zIndex={15}
      icon={
        countryParam === ECountry.HK
          ? ProductPin
          : {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "transparent",
              fillOpacity: 1.0,
              strokeWeight: 6,
              // strokeOpacity: 0.8,
              strokeColor: "transparent",
              rotation: 30,
            }
      }
      title={marker.category ? marker.category.parentCategory.name : ""}
      position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
      onClick={(e) =>
        countryParam === ECountry.HK ? handleShowMarkerModal() : handleNavigateSubcategory()
      }
      label={{
        // text:
        //   Number(marker.totalLocations) > 1
        //     ? `${
        //         Number(marker.totalLocations) < 10
        //           ? `0${marker.totalLocations}`
        //           : `${marker.totalLocations}`
        //       }
        //     : "1",
        text: marker.id.toString(),
        className: "markerLabel",
      }}
    >
      {countryParam === ECountry.AU && (
        <OverlayViewF
          position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div
            className="custom-pin"
            style={marker?.category?.colorCode ? { background: marker?.category?.colorCode } : {}}
          >
            <img src={marker?.category?.parentCategory?.logo} alt="fish" />
          </div>
        </OverlayViewF>
      )}
      {infoWindowOpen && selectedMarkerId === marker.id && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <div className="marker-popup" ref={markerRef}>
            <Image
              width={"100%"}
              src={marker?.headingImage || marker?.category?.logo || NoDataProduct}
              alt="market image"
              preview={false}
              className="market-image"
            />
            <h2 className="market-name">{marker?.name || marker?.category?.name}</h2>
            <p className="market-description">{marker?.description}</p>
            {listSubcategories && listSubcategories?.length > 0 && (
              <div className="market-action" onClick={handleOpenSubcategoryListModal}>
                {t("map.viewAll")} {listSubcategories?.length}{" "}
                {t("map.subCategoriesInThisLocation")}
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerMap;
