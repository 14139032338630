/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from "react-router";
import { AppStoreLogo, PlayStoreLogo, TierhubLogo } from "src/assets/icons";
import { PATHS } from "src/constants/paths";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const ListContact = [
  {
    label: "footer.contactUs",
    link: "",
  },
  {
    label: "footer.about",
    link: "",
  },
  {
    label: "footer.terms",
    link: "",
  },
  {
    label: "footer.privacy",
    link: "",
  },
  {
    label: "footer.contact",
    link: "",
  },
];

const DefaultFooter = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="default-footer">
      <div className="footer-left">
        <img
          className="footer-logo"
          src={TierhubLogo}
          alt="image_s"
          onClick={() => history.push(PATHS.default())}
        />
        <div className="footer-divider"></div>
        <div>
          <ul className="footer-links">
            {ListContact.map((contact: any) => {
              return (
                <li>
                  <div className="marker"></div>
                  <a target="_blank" href={contact?.link} rel="noreferrer">
                    {t(contact?.label)}
                  </a>
                </li>
              );
            })}
          </ul>
          <p className="copyright">CompanyName @ 202X. All rights reserved.</p>
        </div>
      </div>
      <div className="footer-right">
        <a href="#">
          <img
            className="footer-logo"
            src={AppStoreLogo}
            alt="image_s"
            onClick={() => history.push(PATHS.default())}
          />
        </a>
        <a href="#">
          <img
            className="footer-logo"
            src={PlayStoreLogo}
            alt="image_s"
            onClick={() => history.push(PATHS.default())}
          />
        </a>
      </div>
      <p className="copyright-mobile">CompanyName @ 202X. All rights reserved.</p>
    </div>
  );
};

export default DefaultFooter;
