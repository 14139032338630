export const STATUS_CODE = {
  SUCCESS: 200,
  SUCCESS_201: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
};

export const VALIDATION_ERROR_CODE = "VALIDATION_ERROR";

export const EXISTED_LINK_ACCOUNT_ERROR_CODE = "REGISTERED_WITH_OTHER_METHOD";
