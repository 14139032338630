import loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { USER_STATUS } from "src/constants/login";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import Map from "src/pages/home";
import HomePage from "src/pages/homepage";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import PasswordChanged from "src/pages/login/PasswordChanged";
import { UserState } from "src/store/reducers/user";
import { PrivateRoute } from "./components/PrivateRoute";
import QRScan from "./components/QRScan";
import LayoutDefault from "src/components/01.layout";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/PageState"));
const LoginPage = loadableWFallback(() => import("src/pages/login"));
const ListQRCode = loadableWFallback(() => import("src/pages/QR-code"));
const ProductManagement = loadableWFallback(() => import("src/pages/product-management"));
const LogisticInformation = loadableWFallback(() => import("src/pages/logistic-information"));
const UpdateProduct = loadableWFallback(() => import("src/pages/update-product"));
const InternalManagement = loadableWFallback(() => import("src/pages/internal-management"));
const CreateMember = loadableWFallback(
  () => import("src/pages/internal-management/create-members"),
);
const LogisticManagement = loadableWFallback(() => import("src/pages/logistic-management"));
const Registration = loadableWFallback(() => import("src/pages/register/Registration"));
const Notification = loadableWFallback(() => import("src/pages/notification"));
const ForgotPassword = loadableWFallback(() => import("src/pages/forgot-password"));
const ForgotSuccess = loadableWFallback(() => import("src/pages/forgot-success"));
const LastScanExpandView = loadableWFallback(() => import("src/pages/expand-view-last-scan"));
const LastFeedbackExpandView = loadableWFallback(
  () => import("src/pages/expand-view-last-feedback"),
);

const ProductView = loadableWFallback(() => import("src/pages/product-view"));
const ProductCategory = loadableWFallback(() => import("src/pages/product-category"));
const YourAdventure = loadableWFallback(() => import("src/pages/your-adventure"));
const ProductCategoryView = loadableWFallback(
  () => import("src/pages/product-category/product-category-view"),
);

const PersonalSetting = loadableWFallback(() => import("src/pages/personal-information"));
const PersonalInfoSetting = loadableWFallback(
  () => import("src/pages/personal-information/personal-info-setting"),
);
const BusinessInfoSetting = loadableWFallback(
  () => import("src/pages/personal-information/business-info-setting"),
);
const BankDetailSetting = loadableWFallback(
  () => import("src/pages/personal-information/bank-detail-setting"),
);
const LicenseSetting = loadableWFallback(
  () => import("src/pages/personal-information/license-setting"),
);
const IntroductionSetting = loadableWFallback(
  () => import("src/pages/personal-information/introduction-setting"),
);
const NewFeed = loadableWFallback(() => import("src/pages/personal-information/new-feed"));
const NotificationSetting = loadableWFallback(
  () => import("src/pages/personal-information/notification-setting"),
);
const LanguageSetting = loadableWFallback(
  () => import("src/pages/personal-information/language-setting"),
);
const PersonalChangePassword = loadableWFallback(
  () => import("src/pages/personal-information/personal-change-password"),
);
const DetailTracking = loadableWFallback(() => import("src/pages/detail-tracking"));
const HelpCenter = loadableWFallback(
  () => import("src/pages/personal-information/personal-help-center"),
);
const SubcategoryDetail = loadableWFallback(() => import("src/pages/subcategory-detail"));
const RegionDetail = loadableWFallback(() => import("src/pages/region-detail"));
const ProducerDetail = loadableWFallback(() => import("src/pages/producer-detail"));
const RestaurantDetail = loadableWFallback(() => import("src/pages/restaurant-detail"));
const HomepageDetail = loadableWFallback(() => import("src/pages/homepage/section-detail"));

const LayoutDashboardPrivate: React.FC<RouteProps> = (props) => {
  return (
    <LayoutDefault>
      <PrivateRoute {...props} />
    </LayoutDefault>
  );
};

const LayoutDashboard: React.FC<RouteProps> = (props) => {
  return (
    <LayoutDefault>
      <Route {...props} />
    </LayoutDefault>
  );
};

const LayoutEmpty: React.FC<RouteProps> = (props) => {
  return <Route {...props} />;
};

const Routes: React.FC = () => {
  const user: UserState = useSelector((state: any) => state.user);

  const canAccessLoginPage = !hasStorageJwtToken || user.status !== USER_STATUS.APPROVED;

  return (
    <Switch>
      <LayoutDashboard exact path="/" render={() => <Redirect to={PATHS.default()} />} />
      <LayoutDashboard exact path={PATHS.default()} component={HomePage} />
      <LayoutDashboard exact path={`${PATHS.default()}/:sectionType`} component={HomepageDetail} />
      <LayoutDashboard exact path={PATHS.map()} component={Map} />
      <LayoutDashboard exact path={PATHS.qrScan()} component={QRScan} />
      <LayoutDashboard
        exact
        path={PATHS.login()}
        render={() => (canAccessLoginPage ? <LoginPage /> : <Redirect to={PATHS.default()} />)}
      />
      <LayoutDashboard
        exact
        path={PATHS.forgotPassword()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotPassword />
        }
      />
      <LayoutDashboard
        exact
        path={PATHS.forgotSuccess()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotSuccess />
        }
      />
      <LayoutDashboard exact path={PATHS.passwordChanged()} component={PasswordChanged} />
      <LayoutDashboard
        exact
        path={PATHS.registration()}
        // render={() =>
        //   hasStorageJwtToken() &&
        //   !getStorageRegisterThirdParty() ? (
        //     <Redirect to={PATHS.default()} />
        //   ) : (
        //     <Registration />
        //   )
        // }
        render={() => <Registration />}
      />
      <LayoutDashboard
        exact
        path={`${PATHS.subcategoryDetail()}/:id`}
        component={SubcategoryDetail}
      />
      <LayoutDashboard exact path={`${PATHS.producerDetail()}/:id`} component={ProducerDetail} />
      <LayoutDashboard
        exact
        path={`${PATHS.restaurantDetail()}/:id`}
        component={RestaurantDetail}
      />
      <LayoutDashboard exact path={`${PATHS.regionDetail()}/:id`} component={RegionDetail} />
      <Route exact path="/qr-scan" component={QRScan} />
      <LayoutDashboardPrivate exact path={PATHS.listQRCode()} component={ListQRCode} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.internalManagement()}
        component={InternalManagement}
      />
      <LayoutDashboardPrivate exact path={PATHS.createMember()} component={CreateMember} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.logisticManagement()}
        component={LogisticManagement}
      />
      <LayoutDashboardPrivate exact path={PATHS.lastScan()} component={LastScanExpandView} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.lastFeedback()}
        component={LastFeedbackExpandView}
      />
      <LayoutDashboard exact path={`${PATHS.productView()}/:id`} component={ProductView} />
      <LayoutDashboard exact path={`${PATHS.detailTracking()}/:id`} component={DetailTracking} />
      <LayoutDashboardPrivate exact path={PATHS.productCategory()} component={ProductCategory} />
      <LayoutDashboardPrivate exact path={PATHS.yourAdventure()} component={YourAdventure} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.productCategoryView()}
        component={ProductCategoryView}
      />
      {/* <LayoutDashboardPrivate exact path={PATHS.updateProduct()} component={UpdateProductInformation} /> */}
      <LayoutDashboardPrivate exact path={PATHS.notification()} component={Notification} />
      <LayoutDashboardPrivate
        exact
        path={`${PATHS.productManagement()}/:id`}
        component={ProductManagement}
      />
      <LayoutDashboardPrivate
        exact
        path={`${PATHS.logisticInformation()}/:qrCodeId`}
        component={LogisticInformation}
      />
      <LayoutDashboardPrivate
        exact
        path={`${PATHS.updateProduct()}/:qrCodeId`}
        component={UpdateProduct}
      />
      {/* <LayoutDashboardPrivate
        exact
        path={`${PATHS.subcategoryDetail()}/:id`}
        component={SubcategoryDetail}
      />
      <LayoutDashboardPrivate
        exact
        path={`${PATHS.producerDetail()}/:id`}
        component={ProducerDetail}
      />
      <LayoutDashboardPrivate
        exact
        path={`${PATHS.restaurantDetail()}/:id`}
        component={RestaurantDetail}
      />
      <LayoutDashboardPrivate exact path={`${PATHS.regionDetail()}/:id`} component={RegionDetail} /> */}
      <LayoutDashboardPrivate exact path={PATHS.setting()} component={PersonalSetting} />
      <LayoutDashboardPrivate exact path={PATHS.personalInfo()} component={PersonalInfoSetting} />
      <LayoutDashboardPrivate exact path={PATHS.businessInfo()} component={BusinessInfoSetting} />
      <LayoutDashboardPrivate exact path={PATHS.bankDetailInfo()} component={BankDetailSetting} />
      <LayoutDashboardPrivate exact path={PATHS.licenceInfo()} component={LicenseSetting} />
      <LayoutDashboardPrivate exact path={PATHS.newFeed()} component={NewFeed} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.notificationSettings()}
        component={NotificationSetting}
      />
      <LayoutDashboardPrivate exact path={PATHS.languageSettings()} component={LanguageSetting} />
      <LayoutDashboardPrivate
        exact
        path={PATHS.introductionInfo()}
        component={IntroductionSetting}
      />
      <LayoutDashboardPrivate exact path={PATHS.password()} component={PersonalChangePassword} />
      <LayoutDashboardPrivate exact path={PATHS.helpCenter()} component={HelpCenter} />

      <LayoutEmpty path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
