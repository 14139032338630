import { Tooltip, TooltipProps } from "antd";
import "./styles.scss";

type TierTooltipProps = TooltipProps & {
  children?: any;
};

const TierTooltip: React.FC<TierTooltipProps> = (props) => {
  const { children, overlayClassName, ...other } = props;

  return (
    <Tooltip
      color="white"
      {...other}
      overlayClassName={`${overlayClassName ?? "otori-tooltip-overlay"}`}
    >
      {props.children}
    </Tooltip>
  );
};

export default TierTooltip;
