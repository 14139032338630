import { InfoCircleOutlined } from "@ant-design/icons";
import "@reach/combobox/styles.css";
import { Button, Drawer, Form, FormInstance } from "antd";
import { DefaultOptionType } from "rc-select/lib/Select";
import { Dispatch, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Chart, CloseSearchModalIcon } from "src/assets/icons";
import { setHideSearchModal } from "src/store/actions/search-modal";
import { ECountry } from "src/types/country";
import TierTooltip from "../04.tooltip";
import TierHubAutoComplete from "../22.autocomplete";
import MapAustralia from "./Australia";
import MapHongkong from "./Hongkong";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { PATHS } from "src/constants/paths";

interface IMapProps {
  // setMapData: Dispatch<SetStateAction<IMapData>>;
  categoryLocations: any;
  country: string;
  selectedRegions: string[];
  selectedCountry: ECountry;
  // setSelectCountry: Dispatch<SetStateAction<ECountry>>;
  changeSelectRegions: Function;
  searchOptions: DefaultOptionType[] | undefined;
  searchSubcategoryValue: string;
  setSearchSubcategoryValue: Dispatch<SetStateAction<string>>;
  // mapFrame: CategoriesFrame | null;
  form: FormInstance;
  regionOptions: any[];
}

const Map: FC<IMapProps> = ({
  // setMapData,
  categoryLocations,
  country,
  regionOptions,
  selectedRegions,
  selectedCountry,
  // setSelectCountry,
  searchOptions = [],
  searchSubcategoryValue,
  setSearchSubcategoryValue,
  changeSelectRegions,
  // mapFrame,
  form,
}) => {
  const { t } = useTranslation();
  const isShowSearchModal = useSelector((state: any) => state.searchModal.isShowSearchModal);
  const dispatch = useDispatch();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleChangeCountry = () => {
    const nextCountry = selectedCountry === ECountry.AU ? ECountry.HK : ECountry.AU;
    history.push(PATHS.map() + "?country=" + nextCountry);
  };

  const handleSearchChange = (text: string) => {
    setSearchSubcategoryValue(text);
  };

  const handleCloseSearchModal = () => {
    form.setFieldValue("subcategory", null);
    setSearchSubcategoryValue("");
    dispatch(setHideSearchModal());
  };

  return (
    <>
      <div id="map" className="places-container">
        <div className="category-search">
          {/* <TierHubAutoComplete
            options={subcategorySuggestOptions}
            className="sub-category-search"
            onSelect={onSelect}
            onSearch={handleSearchChange}
            value={searchSubcategoryValue}
            popupClassName="sub-category-search-popup"
            notFoundContent={
              <div className="no-data-dropdown">
                <NoResultsData />
                <span className="">{t("search.noResults")}</span>
              </div>
            }
            dropdownRender={(menu) => (searchSubcategoryValue ? menu : <></>)}
          /> */}
          {country === ECountry.HK ? (
            <TierTooltip
              title={t("map.hongkongPriceTooltip")}
              placement="bottomRight"
              // trigger={Device.isMobile ? "click" : undefined}
            >
              <div className="button-price-map">
                {/* {t("map.hongkongPrice")} */}
                <InfoCircleOutlined />
              </div>
            </TierTooltip>
          ) : (
            <TierTooltip title={t("map.auPriceTooltip")} placement="bottomRight">
              <div className="button-price-map">
                {/* {t("map.hongkongPrice")} */}
                <InfoCircleOutlined />
              </div>
            </TierTooltip>
          )}
        </div>

        {country === ECountry.HK ? (
          <MapHongkong data={categoryLocations} />
        ) : (
          // <>
          //   {showMap ? (
          <MapAustralia
            data={categoryLocations}
            regionOptions={regionOptions}
            selectedRegions={selectedRegions}
            changeSelectRegions={changeSelectRegions}
          />
          //   ) : (
          //     <ProductCategories categories={categories} />
          //   )}
          // </>
        )}
        <div className="map-buttons">
          {/* {country === ECountry.AU ? (
            <Button className="button-show-map" onClick={() => setShowMap(!showMap)}>
              <img src={NoteText} alt="button icon" />
              {showMap ? "Show List" : "Show Map"}
            </Button>
          ) : null} */}

          <Button name="australia" className="button-change-map" onClick={handleChangeCountry}>
            <img src={Chart} alt="button icon" />
            <span>
              {country === ECountry.AU ? t("map.hongkongPrice") : t("map.australiaDistribution")}
            </span>
          </Button>
        </div>
        <Drawer
          placement={"right"}
          width={"100%"}
          onClose={handleCloseSearchModal}
          open={isShowSearchModal}
          headerStyle={{ display: "none" }}
          className="search-modal"
        >
          <div className="category-search-mobile">
            <div className="category-search-mobile__header">
              <Button
                className="close-search-btn"
                icon={<CloseSearchModalIcon />}
                onClick={handleCloseSearchModal}
                type="text"
              ></Button>

              <Form.Item name="subcategory" className="sub-category-search">
                <TierHubAutoComplete
                  options={searchOptions}
                  onChange={handleSearchChange}
                  value={searchSubcategoryValue}
                  popupClassName="sub-category-search-popup"
                  dropdownRender={() => <></>}
                />
              </Form.Item>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default Map;
