import { Avatar, Collapse, Space } from "antd";
import { memo, useEffect, useMemo, useState } from "react";
import TierHubCheckBox from "src/components/09.checkbox";
import { ICategory } from "src/types/categories";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { ECountry, RegionsData } from "src/types/country";
import TierHubAutoComplete from "src/components/22.autocomplete";
import {
  ChevronDown,
  ChevronUp,
  MinusIcon,
  NoResultsData,
  PlusIcon,
  SeafoodIcon,
} from "src/assets/icons";
import { useTranslation } from "react-i18next";
import ButtonOutLined from "src/components/08.buttons/ButtonOutLined";
import ButtonContained from "src/components/08.buttons/ButtonContained";
import OverflowTooltip from "src/components/34.overflow-tooltip";
import "./styles.scss";
import { CategoryService } from "src/services/category-service";

interface IProps {
  categories: ICategory[] | undefined;
  subCategories: ICategory[] | undefined;
  regionOptions: RegionsData[];
  onChangeFilter: Function;
  highlightRegion: string[];
  selectedCountry: ECountry;
  fishMarkets: any;
}

const FAKE_ELEMENT = 0;

const FilterMap: React.FC<IProps> = ({
  fishMarkets,
  categories,
  subCategories,
  regionOptions,
  onChangeFilter,
  highlightRegion,
  selectedCountry,
}) => {
  const { t } = useTranslation();
  const categoryService = new CategoryService();
  const countryParam = new URLSearchParams(window.location.search).get("country");

  const [checkAll, setCheckAll] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  // const [selectedSearchValue, setSelectedSearchValue] = useState({ prev: 0, current: 0 });
  const [preResult, setPreResult] = useState(0);
  const [selectedSearchValue, setSelectedSearchValue] = useState(0);
  const [subCategoriesSuggestions, setSubCategoriesSuggestions] = useState<ICategory[]>([]);
  const [openCategories, setOpenCategories] = useState<number[]>([]);

  const regionCodes = (regionOptions || [])?.map((item) => item?.regionCode);
  const categoryIds = (categories || [])?.map((item) => item?.id);
  const subCategoryIds = (subCategories || [])?.map((item) => item?.id);
  const fishMarketIds = (fishMarkets || [])?.map((item: any) => item?.id);

  const [selectedFishMarkets, setSelectedFishMarkets] = useState<number[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState<number[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);

  const selectedCategoriesLength = selectedCategories.length;
  const selectedSubcategoriesLength = selectedSubcategories.length;
  const selectedRegionsLength = selectedRegions.length;
  const selectedFishMarketsLength = selectedFishMarkets.length;

  const isUncheckAll =
    !checkAll &&
    selectedCategoriesLength === 0 &&
    selectedSubcategoriesLength === 0 &&
    selectedRegionsLength === 0 &&
    (countryParam === ECountry.AU || selectedFishMarketsLength === 0);

  useEffect(() => {
    setSelectedCategories(categoryIds);
  }, [categories]);

  useEffect(() => {
    setSelectedSubcategories(subCategoryIds);
  }, [subCategories]);

  useEffect(() => {
    setSelectedRegions(regionCodes);
  }, [regionOptions]);

  useEffect(() => {
    setSelectedFishMarkets(fishMarketIds);
  }, [fishMarkets]);

  useEffect(() => {
    handleResetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, countryParam]);

  useEffect(() => {
    //Update checkbox when user click a region on map
    if (highlightRegion.length === regionCodes.length) {
      setSelectedRegions(regionCodes);
    } else if (highlightRegion.length !== 0) {
      setSelectedRegions(highlightRegion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightRegion]);

  useEffect(() => {
    //Update checkbox subcategories when user selected a search option
    // if (selectedSearchValue.current === 0) return;
    // const newArr = selectedSubcategories.filter((item) => item !== selectedSearchValue.prev);
    // setSelectedSubcategories([...newArr, selectedSearchValue.current]);

    if (selectedSearchValue === 0) return;
    setSelectedSubcategories([...selectedSubcategories, selectedSearchValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchValue]);

  useEffect(() => {
    getPreResult(
      selectedCategories,
      selectedSubcategories,
      selectedRegions.includes(FAKE_ELEMENT) ? [] : selectedRegions,
      selectedFishMarkets,
      selectedCountry,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, selectedSubcategories, selectedRegions, selectedFishMarkets]);

  const getPreResult = async (
    categoryIds: number[],
    subcategoryIds: number[],
    regionIds: any[],
    fishMarketIds: number[],
    selectedMap: ECountry,
  ) => {
    try {
      // setSelectedRegions(regionIds);
      if (selectedMap === ECountry.HK) {
        const { data } = await categoryService.getMapLocationsCategory2({
          fishMarketId: fishMarketIds,
          subCategoryIds: isUncheckAll ? [FAKE_ELEMENT] : subcategoryIds,
          categoryIds: categoryIds,
          region: regionIds,
        });
        setPreResult((data || []).length);
      } else {
        const { data } = await categoryService.getMapLocationsCategory1({
          subCategoryIds: isUncheckAll ? [FAKE_ELEMENT] : subcategoryIds,
          categoryIds: categoryIds,
          regionCode: regionIds,
        });
        setPreResult((data || []).length);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const handleResetFilter = () => {
  //   setCheckAll(false);
  //   setSearchValue("");
  //   setSelectAllCategories(false);
  //   setSelectAllSubcategories(false);
  //   setSelectAllRegion(false);
  //   setSelectAllFishMarket(false);
  //   setSelectedRegions([]);
  //   setSelectedFishMarkets([]);
  //   setSelectedCategories([]);
  //   setSelectedSubcategories([]);
  //   // setSelectedSearchValue({ prev: 0, current: 0 });
  //   setSelectedSearchValue(0);
  //   onChangeFilter([], [], [], [], selectedCountry);
  // };

  const handleResetFilter = () => {
    setCheckAll(true);
    setSearchValue("");
    setSelectedRegions(regionCodes);
    setSelectedFishMarkets(fishMarketIds);
    setSelectedCategories(categoryIds);
    setSelectedSubcategories(subCategoryIds);
    // setSelectedSearchValue({ prev: 0, current: 0 });
    setSelectedSearchValue(0);
    onChangeFilter([], [], regionCodes, [], selectedCountry);
  };

  const onChangeCheckAll = () => {
    if (checkAll) {
      setCheckAll(false);
      setSelectedCategories([]);
      setSelectedSubcategories([]);
    } else {
      const categoryIds = (categories || [])?.map((item) => item?.id);
      const subCategoryIds = (subCategories || [])?.map((item) => item?.id);
      setCheckAll(true);
      setSelectedCategories(categoryIds);
      setSelectedSubcategories(subCategoryIds);
    }
  };

  const handleSelectRegion = (regionCode: any) => {
    if (selectedRegions.includes(regionCode)) {
      //remove
      const newArr = selectedRegions.filter((item) => item !== regionCode);
      setSelectedRegions(newArr);
    } else {
      //add
      const newArr = [...selectedRegions, regionCode];
      setSelectedRegions(newArr);
    }
  };

  const handleSelectFishMarket = (fishMarketId: any) => {
    if (selectedFishMarkets.includes(fishMarketId)) {
      //remove
      const newArr = selectedFishMarkets.filter((item: any) => item !== fishMarketId);
      setSelectedFishMarkets(newArr);
    } else {
      //add
      const newArr = [...selectedFishMarkets, fishMarketId];
      setSelectedFishMarkets(newArr);
    }
  };

  const handleSelectCategory = (categoryId: any) => {
    if (!categoryId) return;
    const convertedCategoryId = Number(categoryId);

    const subCategoriesList = (subCategories || [])
      .filter((item) => Number(item?.parentId) === convertedCategoryId)
      .map((item) => item.id);

    if (selectedCategories.includes(convertedCategoryId)) {
      //remove
      const newArr = selectedCategories.filter((item) => item !== convertedCategoryId);
      setSelectedCategories(newArr);
      setCheckAll(false);

      //remove all corresponding subcategories
      const newSubArr = selectedSubcategories.filter((item) => !subCategoriesList?.includes(item));
      setSelectedSubcategories(newSubArr);
    } else {
      //add
      const newArr = [...selectedCategories, convertedCategoryId];
      setSelectedCategories(newArr);
      setOpenCategories([...openCategories, convertedCategoryId]);
      if (newArr.length === (categories || []).length) setCheckAll(true);

      //add all corresponding subcategories
      const newSubArr = [...selectedSubcategories, ...subCategoriesList];
      setSelectedSubcategories(newSubArr);
    }
  };

  const handleSelectSubcategory = (subcategory: any) => {
    if (!subcategory) return;
    const convertedSubCategoryId = Number(subcategory?.id);

    const sameParentSubcategories = (subCategories || [])
      .filter((item) => item.parentId === subcategory.parentId)
      .map((item) => item?.id);

    if (selectedSubcategories.includes(convertedSubCategoryId)) {
      //remove
      const newArr = selectedSubcategories.filter((item) => item !== convertedSubCategoryId);
      setSelectedSubcategories(newArr);
      setCheckAll(false);

      //remove parent category
      if (!newArr.find((item) => sameParentSubcategories.includes(item))) {
        const newArr = selectedCategories.filter((item) => item !== subcategory?.parentId);
        setSelectedCategories(newArr);
      }
    } else {
      //add
      const newArr = [...selectedSubcategories, convertedSubCategoryId];
      setSelectedSubcategories(newArr);

      //add parent category
      if (!selectedCategories.find((item) => item === subcategory?.parentId)) {
        const newCategoryArr = [...selectedCategories, subcategory?.parentId];
        setSelectedCategories(newCategoryArr);
      }
    }
  };

  const searchOptions = useMemo(() => {
    return subCategoriesSuggestions?.map((item) => ({
      value: item.id.toString(),
      label: (
        <div className="subcategory-option">
          <div className="subcategory-option__image">
            <img src={item.logo} alt="subcategory-option" />
          </div>
          <div className="subcategory-option__name">{item.name}</div>
        </div>
      ),
      customValue: item.name,
    }));
  }, [subCategoriesSuggestions]);

  const subcategorySuggestOptions = useMemo(
    () => (!searchValue ? [] : searchOptions),
    [searchOptions, searchValue],
  );

  const onSelect = (data: string, option: any) => {
    setSearchValue(option.customValue);
    handleSearchChange(option.customValue);
    // setSelectedSearchValue({ prev: selectedSearchValue.current, current: Number(data) });
    const subcategoryInfo = (subCategories || []).find((item) => item.id === Number(data));
    setSelectedSearchValue(Number(data));
    setSelectedCategories([...selectedCategories, subcategoryInfo?.parentId || 0]);
    onChangeFilter(
      [...selectedCategories, subcategoryInfo?.parentId || 0],
      [...selectedSubcategories, Number(data)],
      selectedRegions,
      selectedFishMarkets,
      selectedCountry,
    );
  };

  const handleSearchChange = (text: string) => {
    setSearchValue(text);
    const newArr = (subCategories || []).filter((item) => {
      const convertedSearchText = (text || "").toLowerCase();
      const convertedSubCategoryName = (item.name || "").toLowerCase();
      return convertedSubCategoryName.includes(convertedSearchText);
    });
    setSubCategoriesSuggestions(newArr);
  };

  const handleConfirmFilter = () => {
    if (isUncheckAll) {
      onChangeFilter([], [FAKE_ELEMENT], [FAKE_ELEMENT], [], selectedCountry);
    } else {
      onChangeFilter(
        selectedCategories,
        selectedSubcategories,
        selectedRegions.includes(FAKE_ELEMENT) ? [] : selectedRegions,
        selectedFishMarkets,
        selectedCountry,
      );
    }
  };

  return (
    <div className="filter-wrapper">
      <div className="filter-input">
        <div className="search-container">
          <TierHubAutoComplete
            options={subcategorySuggestOptions}
            className="sub-category-search"
            onSelect={onSelect}
            onSearch={handleSearchChange}
            value={searchValue}
            popupClassName="sub-category-search-popup"
            notFoundContent={
              <div className="no-data-dropdown">
                <NoResultsData />
                <span className="">{t("search.noResults")}</span>
              </div>
            }
            dropdownRender={(menu) => (searchValue ? menu : <></>)}
          />
        </div>
        <Collapse
          defaultActiveKey={["1", "2", "3"]}
          expandIconPosition="right"
          expandIcon={(values) => {
            if (values.isActive) return <MinusIcon />;
            else return <PlusIcon />;
          }}
        >
          <CollapsePanel header="Category" key="1">
            <div className="category-wrapper">
              <Space size={16} className="action-btn" onClick={onChangeCheckAll}>
                <TierHubCheckBox checked={checkAll} />
                <Space size={12}>
                  <SeafoodIcon />
                  <span className="option-text">All</span>
                </Space>
              </Space>
            </div>
            {(categories || []).map((category) => {
              const subCategoriesList = (subCategories || []).filter(
                (item) => item?.parentId === category?.id,
              );
              const isOpen = openCategories.includes(Number(category?.id));

              return (
                <div key={category.id}>
                  <div className="category-wrapper">
                    <Space
                      size={16}
                      className="action-btn"
                      onClick={() => handleSelectCategory(category?.id)}
                    >
                      <TierHubCheckBox
                        checked={selectedCategories.includes(Number(category?.id))}
                      />
                      <Space size={12}>
                        <Avatar size={28} src={category?.logo} />
                        <span className="option-text">{category?.name}</span>
                      </Space>
                    </Space>
                    {subCategoriesList.length === 0 ? null : isOpen ? (
                      <ChevronUp
                        className="action-btn"
                        onClick={() =>
                          setOpenCategories([
                            ...openCategories.filter((item) => item !== Number(category?.id)),
                          ])
                        }
                      />
                    ) : (
                      <ChevronDown
                        className="action-btn"
                        onClick={() => setOpenCategories([...openCategories, Number(category?.id)])}
                      />
                    )}
                  </div>
                  {isOpen && (
                    <div
                      className={`subcategory-wrapper ${
                        subCategoriesList.length === 0 ? "empty-subcategory" : ""
                      }`}
                    >
                      {subCategoriesList.map((subcategory) => (
                        <Space
                          size={16}
                          className="action-btn"
                          onClick={() => handleSelectSubcategory(subcategory)}
                        >
                          <TierHubCheckBox
                            checked={selectedSubcategories.includes(Number(subcategory?.id))}
                          />
                          <Space size={12}>
                            <Avatar size={20} style={{ backgroundColor: subcategory?.colorCode }} />
                            <span className="option-text">{subcategory?.name}</span>
                          </Space>
                        </Space>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </CollapsePanel>
          <CollapsePanel header="States" key="2">
            {(regionOptions || []).map((item) => {
              return (
                <div key={item.id} className="category-wrapper">
                  <Space
                    size={16}
                    className="action-btn"
                    onClick={() => handleSelectRegion(item?.regionCode)}
                  >
                    <TierHubCheckBox checked={selectedRegions.includes(item?.regionCode)} />
                    <OverflowTooltip title={item?.region}>
                      <span className="option-text">{item?.region}</span>
                    </OverflowTooltip>
                  </Space>
                </div>
              );
            })}
          </CollapsePanel>
          {countryParam === ECountry.HK && (
            <CollapsePanel header="Fish market" key="3">
              {(fishMarkets || []).map((item: any) => {
                return (
                  <div key={item.id} className="category-wrapper">
                    <Space
                      size={16}
                      className="action-btn"
                      onClick={() => handleSelectFishMarket(item?.id)}
                    >
                      <TierHubCheckBox checked={selectedFishMarkets.includes(item?.id)} />
                      <OverflowTooltip title={item?.name}>
                        <span className="option-text">{item?.name}</span>
                      </OverflowTooltip>
                    </Space>
                  </div>
                );
              })}
            </CollapsePanel>
          )}
        </Collapse>
      </div>
      <div className="filter-action">
        <ButtonOutLined size="large" onClick={handleResetFilter}>
          Default
        </ButtonOutLined>
        <ButtonContained size="large" onClick={handleConfirmFilter}>
          Filter ({preResult})
        </ButtonContained>
      </div>
    </div>
  );
};

export default memo(FilterMap);
