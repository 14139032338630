import { Avatar, Grid } from "antd";
import { memo, useState } from "react";
import { useHistory } from "react-router";
import { ArrowRightIcon, DefaultImage, DefaultRestaurant, FailedLogo } from "src/assets/icons";
import { MultipleItemsSlick } from "src/components/18.react-slick";
import OverflowTooltip from "src/components/34.overflow-tooltip";
import MediaView from "src/components/35.media-view";
import { PATHS } from "src/constants/paths";
import { HomepageItem } from "src/services/params-type";
import { HomepageSectionType } from "../section-detail";
import "./styles.scss";
import NewsDetailModal, {
  NewsInfo,
} from "src/pages/personal-information/new-feed/news-detail-modal";

interface IProps {
  title: string;
  data?: HomepageItem[];
  customClassName?: string;
  sectionType: HomepageSectionType;
  onExpand: () => void;
  expanText?: string;
  hiddenName?: boolean;
  reduceCardLimit?: number;
  showDots?: boolean;
  expandLimit?: number;
  preview?: boolean;
  // setSectionDetail: React.Dispatch<React.SetStateAction<SectionDetailProps | undefined>>;
}

const { useBreakpoint } = Grid;

const HomepageSection: React.FC<IProps> = ({
  title,
  sectionType,
  // setSectionDetail,
  onExpand,
  data = [],
  customClassName = "",
  expanText = "See more",
  expandLimit,
  hiddenName = false,
  preview = false,
  reduceCardLimit = 0,
  showDots = true,
}) => {
  const history = useHistory();
  const breakpoint = useBreakpoint();

  const [selectedNews, setSelectedNews] = useState<NewsInfo>();

  let cardLimit = 2;
  if (breakpoint.md) cardLimit = 3;
  if (breakpoint.xl) cardLimit = 4;
  if (breakpoint.xl && sectionType === HomepageSectionType.RECIPLE) cardLimit = 5;
  cardLimit = cardLimit - reduceCardLimit > 1 ? cardLimit - reduceCardLimit : 2;

  const renderSubcategoryList = () => {
    return (
      <div className="section-list">
        <MultipleItemsSlick slideToShow={cardLimit} showDots={data?.length > 4 ? showDots : false}>
          {data.map((item, index) => {
            const cardName = item?.name || "";
            return (
              <div
                key={index}
                className="section-item"
                onClick={() => history.push(`${PATHS.subcategoryDetail()}/${item.id}`)}
              >
                <MediaView
                  preview={preview}
                  className="item-img"
                  data={{
                    link: item?.logo || "",
                    thumbnail: item?.thumbnail || "",
                    type: item?.type,
                  }}
                />
                <div className="item-name">
                  <OverflowTooltip title={cardName}>
                    <span className="name">{cardName || "No name"}</span>
                  </OverflowTooltip>
                </div>
              </div>
            );
          })}
        </MultipleItemsSlick>
      </div>
    );
  };

  const renderRegionList = () => {
    return (
      <div className="section-list">
        <MultipleItemsSlick slideToShow={cardLimit} showDots={data?.length > 4 ? showDots : false}>
          {data.map((item, index) => {
            const cardName = item?.name || "";
            return (
              <div
                key={index}
                className="section-item"
                onClick={() => history.push(`${PATHS.regionDetail()}/${item.id}`)}
              >
                <MediaView
                  preview={preview}
                  className="item-img"
                  data={{
                    link: item?.headingImage || "",
                    thumbnail: item?.thumbnail || "",
                    type: item?.type,
                  }}
                />
                <div className="item-name">
                  <OverflowTooltip title={cardName}>
                    <span className="name">{cardName || "No name"}</span>
                  </OverflowTooltip>
                </div>
              </div>
            );
          })}
        </MultipleItemsSlick>
      </div>
    );
  };

  const renderDistributorSection = () => {
    return (
      <div className="section-list">
        <MultipleItemsSlick slideToShow={cardLimit} showDots={data?.length > 4 ? showDots : false}>
          {data.map((item, index) => {
            const srcImg = item.headingImage || item.logo || item.link || item.thumbnail || "";
            const firstMedia = (item?.groupMedia || [])?.[0];
            const imgLink =
              firstMedia?.thumbnail || firstMedia?.link || srcImg || DefaultRestaurant;

            return (
              <div
                className={`section-item`}
                key={index}
                onClick={() => history.push(`${PATHS.restaurantDetail()}/${item.id}`)}
              >
                <MediaView
                  preview={preview}
                  className="item-img"
                  data={{ link: imgLink, thumbnail: item?.thumbnail || "", type: item?.type }}
                />
                <div className="item-name">
                  <Avatar src={item?.logo || DefaultImage} alt="" />
                  <div className="text-group">
                    <OverflowTooltip title={item?.name}>
                      <span className="name">{item?.name || item?.group?.name || "No name"}</span>
                    </OverflowTooltip>
                    <OverflowTooltip title={item?.introduction}>
                      <span className="introduction">{item?.introduction || "No description"}</span>
                    </OverflowTooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </MultipleItemsSlick>
      </div>
    );
  };

  const renderNewsFeedSection = () => {
    return (
      <div className="section-list">
        <MultipleItemsSlick slideToShow={cardLimit} showDots={data?.length > 4 ? showDots : false}>
          {data.map((item, index) => {
            const srcImg = item.headingImage || item.logo || item.link || item.thumbnail || "";
            const firstMedia = (item?.groupMedia || [])?.[0];
            const imgLink = firstMedia?.thumbnail || firstMedia?.link || srcImg || "";

            return (
              <div
                className={`section-item`}
                key={index}
                onClick={() => setSelectedNews(item as NewsInfo)}
              >
                <MediaView
                  key={index}
                  preview={false}
                  className="item-img"
                  data={{ link: imgLink, thumbnail: item?.thumbnail || "", type: item?.type }}
                />
                <div className="item-name newsfeed">
                  <OverflowTooltip title={item?.title}>
                    <span className="name">{item?.title || "TBD"}</span>
                  </OverflowTooltip>
                  <div className="text-group">
                    <Avatar src={item?.group?.logo || DefaultImage} alt="" />
                    <OverflowTooltip title={item?.group?.name}>
                      <span className="name">{item?.group?.name || "TBD"}</span>
                    </OverflowTooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </MultipleItemsSlick>
      </div>
    );
  };

  const renderListItem = () => {
    if (sectionType === HomepageSectionType.SUBCATEGORY) return renderSubcategoryList();
    if (sectionType === HomepageSectionType.CATCH_AREA) return renderRegionList();
    if (sectionType === HomepageSectionType.DISTRIBUTOR) return renderDistributorSection();
    if (sectionType === HomepageSectionType.NEWSFEED) return renderNewsFeedSection();

    return (
      <div className="section-list">
        <MultipleItemsSlick slideToShow={cardLimit} showDots={data?.length > 4 ? showDots : false}>
          {data.map((item, index) => {
            const srcImg = item.headingImage || item.logo || item.link || item.thumbnail || "";
            const cardName = item?.name;

            return (
              <div
                key={index}
                className={`section-item ${
                  sectionType === HomepageSectionType.RECIPLE ? "recipe" : ""
                }`}
              >
                <MediaView
                  preview={preview}
                  className="item-img"
                  data={{ link: srcImg, thumbnail: item?.thumbnail || "", type: item?.type }}
                />
                {!hiddenName && (
                  <div className="item-name">
                    <OverflowTooltip title={cardName}>
                      <span className="name">{cardName || "Not name"}</span>
                    </OverflowTooltip>
                  </div>
                )}
              </div>
            );
          })}
        </MultipleItemsSlick>
      </div>
    );
  };

  return (
    <div className={`homepage-section ${customClassName}`}>
      <div className="section-header">
        <h1 className="section-title">{title}</h1>
        {data.length > (expandLimit || cardLimit) && (
          <div className="section-action" onClick={onExpand}>
            <span className="text">{expanText}</span>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      {(data || []).length !== 0 ? (
        renderListItem()
      ) : (
        <div className="empty-data">
          <img src={FailedLogo} className="empty-icon" />
          <span className="empty-text">No data</span>
        </div>
      )}

      <NewsDetailModal
        open={!!selectedNews}
        newsInfo={selectedNews}
        onCancel={() => setSelectedNews(undefined)}
      />
    </div>
  );
};

export default memo(HomepageSection);
