import { ProductByQrCode } from "src/types/qr-code";
import { ProductByQrCodeParams, ProductParams, ProductResponse } from "../types/Product";
import { ServiceBase } from "./core/service-base";
import { formatQueryParam } from "src/helpers";
// import { MerchantStoreAddress } from '../pages/productManagement/PreviewProduct/PreviewProduct';

export class ProductManagementService extends ServiceBase {
  getProduct = async (params: ProductParams): Promise<ProductResponse> => {
    const res = await this.get(`/admin-management/products`, formatQueryParam(params));
    return res.data;
  };

  getProductByQrCode = async (
    id: string,
    params?: ProductByQrCodeParams,
  ): Promise<ProductByQrCode> => {
    const res = await this.get(`/products/by-qr-code/${id}`, params);
    return res.data;
  };

  getProductDetail = async (id: number | string): Promise<any> => {
    const res = await this.get(`/products/${id}`);
    return res.data;
  };

  getProductDetailByQrCode = async (id: string | number, params: any): Promise<any> => {
    const res = await this.get(`/products/detail/${id}`, params);
    return res;
  };

  createProduct = async (params: any) => {
    const res = await this.post(`/products/harvester`, params);
    return res.data;
  };

  createProductForHarvester = async (params: any) => {
    const res = await this.post(`/products/harvester`, params);
    return res.data;
  };

  createProductForProducer = async (params: any) => {
    const res = await this.post(`/products/producer`, params);
    return res.data;
  };

  createTraceability = async (qrCodeId: number | string, params: any) => {
    const res = await this.post(`/traceability-jouneys/scan/${qrCodeId}`, params);
    return res.data;
  };

  updateTraceability = async (productId: number | string, params: any) => {
    const res = await this.put(`/traceability-jouneys/tiers/${productId}`, params);
    return res.data;
  };

  updateQRCodeClone = async (qrCodeId: number | string, params: any) => {
    const res = await this.post(`/qr-codes/clone/${qrCodeId}`, params);
    return res.data;
  };

  updateStatusProduct = async (productId: number | string) => {
    const res = await this.put(`/products/update-status/${productId}`);
    return res.data;
  };

  updateProductInfo = async (params: any) => {
    const res = await this.post(`/products/scan`, params);
    return res.data;
  };

  getRegions = async (params: any) => {
    const res = await this.get("/regions", params);
    return res;
  };

  searchGlobalProduct = async (params: any) => {
    const res = await this.get("/products", params);
    return res;
  };
}

export const productManagementService = new ProductManagementService();
