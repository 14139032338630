import "@reach/combobox/styles.css";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { KEY_GOOGLE_MAP } from "src/constants/env";

import { Drawer } from "antd";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { ArrowLeftIcon } from "src/assets/icons";
import useWindowSize from "src/hooks/useWindowSize";
import FilterModal from "src/pages/home/filter-modal/FilterModal";
import { CategoryLocation, SubCategoriesAtSamePosition } from "src/types/categories";
import MarkerMap from "./MarkerMap";
import "./styles.scss";

interface IMapHongkong {
  // setMapData: Dispatch<SetStateAction<IMapData>>;
  data: CategoryLocation[];
  // mapFrame: CategoriesFrame | null;
}

const MapHongkong = (props: IMapHongkong) => {
  const { t } = useTranslation();
  const { data } = props;
  const [selectedMarkerId, setSelectedMarkerId] = useState<number>();
  const HongKong = { lat: 22.3220806286703, lng: 114.17072932448195 };
  const [listSubCategories, setListSubcategories] = useState<SubCategoriesAtSamePosition[]>([]);
  const [isListSubCategoryModalOpen, setIsListSubCategoryModalOpen] = useState(false);
  // const [regionCode, setRegionCode] = useState<string>();
  const { isMobileAndTablet } = useWindowSize();
  const MAX_ZOOM = isMobileAndTablet ? 8 : 10;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const center = useMemo(() => HongKong, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: KEY_GOOGLE_MAP,
    libraries: ["marker"],
    language: "en",
    region: "HK",
  });

  const containerStyle = {
    width: "100%",
    height: isMobileAndTablet ? "400px" : "800px",
  };

  const handleGetListSubcategories = (listSub: SubCategoriesAtSamePosition[]) =>
    setListSubcategories(listSub);

  const handleOpenSubcategoryListModal = () => {
    setIsListSubCategoryModalOpen(true);
  };

  // Custom Markers Map
  function addMarkers() {
    const markers = data.map((value: CategoryLocation) => {
      const subcategories = (value?.categoryLocation || []).filter((item) => item.category);
      return (
        <MarkerMap
          marker={value}
          setListSubcategories={handleGetListSubcategories}
          listSubcategories={subcategories}
          selectedMarkerId={selectedMarkerId as number}
          setSelectedMarkerId={(name) => setSelectedMarkerId(name)}
          handleOpenSubcategoryListModal={handleOpenSubcategoryListModal}
        />
      );
    });
    return markers;
  }

  const getMapData = () => {
    // const zoom = maps?.getZoom();
    // // const center = maps?.getCenter();
    // const bounds = maps?.getBounds();
    // const Bounds = {
    //   neLat: bounds?.getNorthEast()?.lat(),
    //   swlat: bounds?.getSouthWest()?.lat(),
    //   nelng: bounds?.getNorthEast()?.lng(),
    //   swlng: bounds?.getSouthWest()?.lng(),
    // };
    // if (!zoom || !bounds) {
    //   setMapData((mapData) => ({
    //     ...mapData,
    //     minLat: 22.17908967404117,
    //     maxLat: 22.46492521485518,
    //     minLon: 113.87667338576125,
    //     maxLon: 114.46478526320266,
    //     zoomLevel: 12,
    //   }));
    // } else {
    //   setMapData((mapData) => ({
    //     ...mapData,
    //     maxLat: Bounds.neLat,
    //     minLat: Bounds.swlat,
    //     maxLon: Bounds.nelng,
    //     minLon: Bounds.swlng,
    //     zoomLevel: zoom,
    //   }));
    // }
  };

  const debounceGetMapData = debounce(() => {
    getMapData();
  }, 300);

  // const centerConfig = useMemo(
  //   () => ({ lat: Number(mapFrame?.cenlat), lng: Number(mapFrame?.cenlon) }),
  //   [mapFrame],
  // );

  // const zoom = useMemo(
  //   () =>
  //     Math.round(Math.log(360 / (Number(mapFrame?.maxLon) - Number(mapFrame?.minLon))) / Math.LN2),
  //   [mapFrame],
  // );

  useEffect(() => {
    setIsListSubCategoryModalOpen(false);
    setListSubcategories([]);
  }, [data]);

  // useEffect(() => {
  //   const zoomLevel = zoom > MAX_ZOOM || !isFinite(zoom) ? MAX_ZOOM : zoom;
  //   maps?.setZoom(zoomLevel);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [maps, zoom, mapFrame]);
  // const convertDataRegion = (stateRegion as any).features.map((region: any) => {
  //   const newRegion = region.geometry.coordinates.map((regions: any, index: number) => {
  //     const flatData = regions.flat(1).map((item: any, index2: number) => {
  //       return { lat: item[1], lng: item[0], regionCode: region.properties.ID };
  //     });
  //     return flatData;
  //   });
  //   return newRegion;
  // });

  // const renderColor = (code: string) => {
  //   switch (code) {
  //     case regionCode:
  //       return {
  //         strokeColor: "green",
  //         strokeOpacity: 0.3,
  //         strokeWeight: 2,
  //         fillColor: "green",
  //         fillOpacity: 0.3,
  //       };
  //     default:
  //       return {
  //         strokeColor: "green",
  //         strokeOpacity: 0,
  //         strokeWeight: 2,
  //         fillColor: "green",
  //         fillOpacity: 0,
  //       };
  //   }
  // };

  return (
    <div>
      {!isLoaded ? (
        <>loading...</>
      ) : (
        <div className="map-container">
          <GoogleMap
            zoom={MAX_ZOOM}
            center={center as any}
            mapContainerStyle={containerStyle}
            // onClick={(e) => console.log("event", e)}
            // onLoad={(map) => {
            //   setMaps(map);
            // }}
            options={{
              gestureHandling: "greedy",
              zoomControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              minZoom: 3,
              maxZoom: 19,
              streetViewControl: false,
              rotateControl: false,
              scaleControl: false,
            }}
            onZoomChanged={() => {
              debounceGetMapData();
            }}
            onDragEnd={() => {
              debounceGetMapData();
            }}
            onCenterChanged={() => {
              debounceGetMapData();
            }}
          >
            {/* {convertDataRegion.map((region: any, index: number) => {
              return (
                <Polygon
                  onClick={() => {
                    console.log(region[index]);
                    if (regionCode == region.flat(2)[index].regionCode) {
                      setRegionCode(undefined);
                    } else {
                      setRegionCode(region.flat(2)[index].regionCode);
                      // changeSelectRegions(region.flat(2)[index].regionCode);
                    }
                  }}
                  // onMouseMove={() => console.log("onMouseMove", region.flat(2)[index].regionCode)}
                  paths={region}
                  options={renderColor(region.flat(2)[index].regionCode)}
                />
              );
            })} */}
            {addMarkers()}
          </GoogleMap>
          {listSubCategories.length > 1 && !isMobileAndTablet ? (
            <div
              className={`close-button ${isListSubCategoryModalOpen ? "open" : "close"}`}
              onClick={() => setIsListSubCategoryModalOpen(!isListSubCategoryModalOpen)}
            >
              <ArrowLeftIcon />
            </div>
          ) : null}

          {isMobileAndTablet ? (
            <FilterModal
              title="Sub-categories"
              open={isListSubCategoryModalOpen}
              onCancel={() => setIsListSubCategoryModalOpen(false)}
              customClass="filter-result-modal"
              footer={false}
            >
              <div className="subcategory-list-modal__result">
                {listSubCategories.length} {t("map.subCategoriesInThisLocation")}
              </div>

              <div className="subcategory-list-modal__list">
                {listSubCategories.map((item) => (
                  <div className="subcategory-item">
                    <div className="subcategory-item__img">
                      <img src={item.logo} alt="image_s" />
                    </div>
                    <div className="subcategory-item__info">
                      <div className="subcategory-item__info__name">{item.name}</div>
                      <div className="subcategory-item__info__price">
                        Avg. Price: <span>{item.avgPrice} USD</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </FilterModal>
          ) : (
            <Drawer
              title="Drawer with extra actions"
              placement={"right"}
              width={278}
              onClose={() => setIsListSubCategoryModalOpen(false)}
              open={isListSubCategoryModalOpen}
              getContainer={false}
              headerStyle={{ display: "none" }}
              className="subcategory-list-modal"
            >
              <div className="subcategory-list-modal__result">
                {listSubCategories.length} {t("map.subCategoriesInThisLocation")}
              </div>

              <div className="subcategory-list-modal__list">
                {listSubCategories.map((item) => (
                  <div className="subcategory-item">
                    <div className="subcategory-item__img">
                      <img src={item?.category?.logo} alt="image_s" />
                    </div>
                    <div className="subcategory-item__info">
                      <div className="subcategory-item__info__name">{item?.category?.name}</div>
                      <div className="subcategory-item__info__price">
                        {t("map.avgPrice")}:{" "}
                        <span>
                          {item.avgPrice} {item.currency}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Drawer>
          )}
        </div>
      )}
    </div>
  );
};

export default MapHongkong;
