import { Image } from "antd";
import React, { useState } from "react";
import { DefaultImage, DefaultPDF, DefaultVideo, PlayVideoThumbnailIcon } from "src/assets/icons";
import { EPRODUCT_MEDIA_TYPE } from "src/constants";
import TierHubModal from "../05.modal";
import ReactPlayer from "react-player";
import "./styles.scss";

export interface IMedia {
  link: string;
  name?: string;
  thumbnail?: string;
  mediaType?: string;
  type?: EPRODUCT_MEDIA_TYPE;
}

interface IProps {
  width?: number | string;
  data: IMedia;
  preview?: boolean;
  className?: string;
  defaultImage?: string;
}

const MediaView: React.FC<IProps> = ({
  data,
  preview = true,
  width = "100%",
  defaultImage,
  className = "",
}) => {
  const [openVideo, setOpenVideo] = useState(false);

  const isImage =
    data?.type === EPRODUCT_MEDIA_TYPE.IMAGE ||
    (!data?.type && data?.type !== EPRODUCT_MEDIA_TYPE.PDF);
  const isVideo = data?.type === EPRODUCT_MEDIA_TYPE.VIDEO;
  const isPDF = data?.type === EPRODUCT_MEDIA_TYPE.PDF;

  const handlePlayVideo = () => {
    if (!preview) return;
    setOpenVideo(true);
  };

  const handleViewPDF = () => {
    window.open(data?.link);
  };

  const handleClickMedia = () => {
    if (isVideo) handlePlayVideo();
    if (isPDF) handleViewPDF();
  };

  const renderDefaultImage = () => {
    if (isPDF) return DefaultPDF;
    if (isVideo) return DefaultVideo;
    return defaultImage || DefaultImage;
  };

  return (
    <div className={`media-view ${className}`} onClick={handleClickMedia}>
      <Image
        className={`${className} image-preview`}
        width={width}
        src={isVideo ? data?.thumbnail || "" : data?.link || ""}
        alt="media"
        onClick={handleClickMedia}
        preview={isImage && preview}
        fallback={renderDefaultImage()}
      />
      {!isImage && preview && (
        <>
          <div className="overlay-media"></div>
          <div className="view-text">{isVideo ? <PlayVideoThumbnailIcon /> : "View PDF"}</div>
        </>
      )}

      {isVideo && (
        <TierHubModal
          open={openVideo}
          wrapClassName="media-video-modal"
          onCancel={(e) => {
            e.stopPropagation();
            setOpenVideo(false);
          }}
          closeIcon={<></>}
        >
          <ReactPlayer stopOnUnmount controls url={data?.link} />
        </TierHubModal>
      )}
    </div>
  );
};

export default MediaView;
