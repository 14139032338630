import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "antd/dist/antd.css";
import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/lib/integration/react";
import "src/assets/scss/_themes.scss";
import "src/assets/scss/variable.scss";
import "./App.scss";
import LocaleProviderComponent from "./components/15.locale-provider";
import { CLIENT_ID_GOOGLE } from "./constants/env";
import ToastContext from "./contexts/toast";
import { config_login_microsoft } from "./helpers/config-login";
import {
  getStorageJwtToken,
  getStorageRememberMe,
  removeStorageJwtToken,
  removeStorageRefreshToken,
} from "./helpers/storage";
import Routes from "./routes/Routes";
import { handleRefreshToken } from "./services/core/http-service";
import initStore from "./store";
import "./i18n";
import { messaging } from "./notifications/firebase";
import { onMessage } from "firebase/messaging";
import { toastMS } from "./components/28.toast-message";

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const { store, persistor } = initStore();
  const [mount, setMount] = useState(false);
  const msalInstance = new PublicClientApplication(config_login_microsoft);

  useEffect(() => {
    (async () => {
      if (getStorageRememberMe() || getStorageJwtToken()) {
        await handleRefreshToken();
      } else {
        removeStorageJwtToken();
        removeStorageRefreshToken();
      }
      setMount(true);
    })();
  }, []);

  useEffect(() => {
    // generateTokenFirebase();
    if (messaging) {
      onMessage(messaging, (payload) => {
        console.log(payload);
        toastMS("success", payload?.notification?.body || "");
      });
    }
  }, []);

  if (!mount) return null;

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate persistor={persistor}>
          <LocaleProviderComponent>
            <MsalProvider instance={msalInstance}>
              <GoogleOAuthProvider clientId={CLIENT_ID_GOOGLE || ""}>
                <BrowserRouter basename="">
                  {/* <WrapperLayout /> */}
                  <ToastContext />
                  {/* <Layout> */}
                  <Routes />
                  {/* </Layout> */}
                </BrowserRouter>
              </GoogleOAuthProvider>
            </MsalProvider>
          </LocaleProviderComponent>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
