import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import "./styles.scss";

export const toastMS = (type: string, message: string, duration: number = 3) => {
    const toast: ArgsProps = {
      message: message,
    //   description: message,
      className:"notification",
    //   style: { backgroundColor: type == "error" ? "red" : "white" },
      duration: duration,
      placement: "topRight",
    };
    switch (type) {
      case "success":
        notification.success(toast);
        break;
      case "info":
        notification.info(toast);
        break;
      case "error":
        notification.error(toast);
        break;
      case "warning":
        notification.warning(toast);
        break;
     default:
        break;
    }
};
