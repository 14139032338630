import { useQuery } from "@tanstack/react-query";
import { Button, Grid, Row, Space } from "antd";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  DefaultImage,
  FilterIcon,
  NoDataProduct,
  SeafoodIcon,
  WhiteArrorRight,
  WhiteArrowLeft,
  WhiteChart,
  WhiteMap,
} from "src/assets/icons";
import TierTooltip from "src/components/04.tooltip";
import ButtonContained from "src/components/08.buttons/ButtonContained";
import { MultipleItemsSlick } from "src/components/18.react-slick";
import OverflowTooltip from "src/components/34.overflow-tooltip";
import MediaView from "src/components/35.media-view";
import { PATHS } from "src/constants/paths";
import { CategoryService } from "src/services/category-service";
import { HomepageService } from "src/services/homepage-service";
import { HomepageInfo } from "src/services/params-type";
import { ICategory } from "src/types/categories";
import { ECountry } from "src/types/country";
import { HomepageSectionType, SectionDetailProps } from "./section-detail";
import HomepageSection from "./section-homepage";
import "./styles.scss";

enum EHomepageTab {
  EXPLORE = "Explore",
  FOLLOW = "Follow",
  NEARBY = "Nearby",
}

const CATEGORY_NAME_LIMIT = 12;

const { useBreakpoint } = Grid;

const Homepage = () => {
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const categoryService = new CategoryService();
  const homepageService = new HomepageService();

  const [currenTab, setCurrentTab] = useState(EHomepageTab.EXPLORE);
  const [filterActive, setFilterActive] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sectionDetail, setSectionDetail] = useState<SectionDetailProps>();

  let cardLimit = 2;
  if (breakpoint.sm) cardLimit = 3;
  if (breakpoint.md) cardLimit = 4;
  if (breakpoint.xl) cardLimit = 5;

  const { data: homepageInfo, refetch } = useQuery({
    queryFn: () => homepageService.getHomePageInfo({ categoryIds: selectedCategory || [] }),
    select(data) {
      return get(data, "data", []) as HomepageInfo;
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (!sectionDetail) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDetail]);

  const { data: categoryData } = useQuery({
    queryKey: ["parent-categories"],
    queryFn: () =>
      categoryService.getCategories({
        hasParent: false,
        isShowed: true,
        // isHKMap: true,
        sortBy: "name",
        direction: "ASC",
      }),
    select(data) {
      return get(data, "data", []) as ICategory[];
    },
  });

  const { data: newFeedData } = useQuery({
    queryKey: ["new-feed"],
    queryFn: () => homepageService.getNewsFeed(),
    select(data) {
      return get(data, "data", []) as ICategory[];
    },
  });
  // if (sectionDetail) {
  //   return (
  //     <SectionDetail
  //       title={sectionDetail.title}
  //       sectionType={sectionDetail.sectionType}
  //       setSectionDetail={setSectionDetail}
  //     />
  //   );
  // }

  const handleClickCategory = (categoryId: any) => {
    if (selectedCategory.includes(categoryId)) {
      const newArr = (selectedCategory || []).filter((item) => item !== categoryId);
      setSelectedCategory(newArr);
    } else {
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  };

  return (
    <div className="homepage">
      <div className="homepage-top">
        <span className="title">Let’s Explore</span>
        <div className="map-btn-group">
          <div
            className="map-btn"
            onClick={() => history.push(PATHS.map() + "?country=" + ECountry.HK)}
          >
            <WhiteArrowLeft />
            <WhiteChart />
            <span>Hong Kong Price Map</span>
          </div>
          <div
            className="map-btn"
            onClick={() => history.push(PATHS.map() + "?country=" + ECountry.AU)}
          >
            <WhiteMap />
            <span>Australia Distribution map</span>
            <WhiteArrorRight />
          </div>
        </div>
      </div>
      <div className="homepage-content transform">
        <div className="homepage-action">
          <div className="filter-group">
            <Space size={12}>
              {Object.values(EHomepageTab).map((value) => (
                <div
                  // onClick={() => setCurrentTab(value)}
                  className={`tab-btn ${value === currenTab ? "active" : ""}`}
                >
                  {value}
                </div>
              ))}
            </Space>
            <Button
              onClick={() => setFilterActive(!filterActive)}
              className={`filter-button ${filterActive ? "active" : ""}`}
            >
              <img src={FilterIcon} alt="filter icon" />
              <span>Filter</span>
            </Button>
          </div>
          {filterActive && (
            <div className="category-list">
              <MultipleItemsSlick>
                <div className="category-item">
                  <SeafoodIcon className="category-img" onClick={() => setSelectedCategory([])} />
                  <span
                    onClick={() => setSelectedCategory([])}
                    className={`category-name ${selectedCategory.length === 0 ? "active" : ""}`}
                  >
                    All
                  </span>
                </div>
                {(categoryData || []).map((category, index) => {
                  const srcImg = category?.logo || DefaultImage;

                  const isSelected = selectedCategory.includes(category?.id);

                  return (
                    <div className="category-item" key={index}>
                      <img
                        src={srcImg}
                        alt="category"
                        className="category-img"
                        onClick={() => handleClickCategory(category?.id)}
                      />
                      <TierTooltip
                        title={category.name?.length > CATEGORY_NAME_LIMIT ? category.name : ""}
                      >
                        <span
                          onClick={() => handleClickCategory(category?.id)}
                          className={`category-name ${isSelected ? "active" : ""}`}
                        >
                          {category?.name}
                        </span>
                      </TierTooltip>
                    </div>
                  );
                })}
              </MultipleItemsSlick>
            </div>
          )}
        </div>
        {(homepageInfo?.latestSubCategories || []).length > 0 && (
          <HomepageSection
            title="What's Fresh"
            customClassName="fresh"
            onExpand={() => history.push(`${PATHS.default()}/${HomepageSectionType.SUBCATEGORY}`)}
            data={homepageInfo?.latestSubCategories}
            sectionType={HomepageSectionType.SUBCATEGORY}
            expandLimit={7}
          />
        )}
        {(homepageInfo?.catchArea || []).length > 0 && (
          <HomepageSection
            title="Catch Area"
            customClassName="catch-area"
            data={homepageInfo?.catchArea}
            onExpand={() => history.push(`${PATHS.default()}/${HomepageSectionType.CATCH_AREA}`)}
            expandLimit={7}
            sectionType={HomepageSectionType.CATCH_AREA}
          />
        )}
      </div>
      {(homepageInfo?.fiveStarsProducers || []).length > 0 && (
        <div className="producer-section">
          <div className="producer-container">
            <h1 className="title">5 Star Producers</h1>
            <div className="producer-list">
              {(homepageInfo?.fiveStarsProducers || []).map((producer, index) => {
                if (index > 9) return null;
                return (
                  <div
                    className={`producer-card basis-${cardLimit}`}
                    onClick={() => history.push(`${PATHS.producerDetail()}/${producer.id}`)}
                  >
                    <img
                      src={producer?.logo || DefaultImage}
                      alt="product"
                      className="producer-logo"
                    />
                    <div className="producer-name">
                      <OverflowTooltip title={producer?.name}>
                        <span>{producer?.name}</span>
                      </OverflowTooltip>
                    </div>
                  </div>
                );
              })}
            </div>
            {(homepageInfo?.fiveStarsProducers || []).length > 7 && (
              <ButtonContained
                onClick={() => history.push(`${PATHS.default()}/${HomepageSectionType.PRODUCERS}`)}
              >
                See more
              </ButtonContained>
            )}
          </div>
        </div>
      )}
      <div className="homepage-content">
        {(homepageInfo?.idealRecipe || []).length > 0 && (
          <HomepageSection
            title="Idea Recipe"
            customClassName="green"
            data={homepageInfo?.idealRecipe}
            onExpand={() => history.push(`${PATHS.default()}/${HomepageSectionType.RECIPLE}`)}
            expandLimit={7}
            sectionType={HomepageSectionType.RECIPLE}
            preview
          />
        )}
        {(homepageInfo?.whereToBuyLists || []).length > 0 && (
          <HomepageSection
            title="Where to Buy"
            customClassName="fresh"
            data={homepageInfo?.whereToBuyLists}
            onExpand={() => history.push(`${PATHS.default()}/${HomepageSectionType.DISTRIBUTOR}`)}
            expandLimit={7}
            sectionType={HomepageSectionType.DISTRIBUTOR}
          />
        )}
      </div>
      {(homepageInfo?.featureProduct || []).length > 0 && (
        <div className="featured-section">
          <h2 className="title">Featured</h2>
          {(homepageInfo?.featureProduct || []).length < cardLimit ? (
            <div className="featured-list">
              {(homepageInfo?.featureProduct || []).map((item) => {
                return (
                  <div className="featured-card">
                    <MediaView
                      preview
                      className="featured-img"
                      data={{
                        link: item?.link || "",
                        thumbnail: item?.thumbnail || "",
                        type: item?.type,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <MultipleItemsSlick showDots={false} slideToShow={cardLimit}>
              {(homepageInfo?.featureProduct || []).map((item) => {
                return (
                  <div className="featured-card">
                    <MediaView
                      preview
                      className="featured-img"
                      data={{
                        link: item?.link || "",
                        thumbnail: item?.thumbnail || "",
                        type: item?.type,
                      }}
                    />
                  </div>
                );
              })}
            </MultipleItemsSlick>
          )}
          {(homepageInfo?.featureProduct || []).length > 7 && (
            <Row align={"middle"} style={{ flexDirection: "column", marginTop: "24px" }}>
              <ButtonContained
                onClick={() => history.push(`${PATHS.default()}/${HomepageSectionType.FEATURED}`)}
              >
                See more
              </ButtonContained>
            </Row>
          )}
        </div>
      )}
      {(newFeedData || []).length > 0 && (
        <div className="homepage-content">
          <HomepageSection
            title="News Feed"
            customClassName="violet"
            data={((newFeedData as any) || []).slice(0, 8)}
            onExpand={() => history.push(`${PATHS.default()}/${HomepageSectionType.NEWSFEED}`)}
            sectionType={HomepageSectionType.NEWSFEED}
            preview
            expandLimit={7}
          />
        </div>
      )}
    </div>
  );
};

export default Homepage;
