import { useQuery } from "@tanstack/react-query";
import { Avatar, Breadcrumb, Col, Grid, Row, Space } from "antd";
import { get } from "lodash";
import React, { memo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ArrowLeftIcon, DefaultImage, DefaultRestaurant } from "src/assets/icons";
import OverflowTooltip from "src/components/34.overflow-tooltip";
import MediaView, { IMedia } from "src/components/35.media-view";
import { Spinner } from "src/components/spinner";
import { TIER } from "src/constants/login";
import { PATHS } from "src/constants/paths";
import { CategoryService } from "src/services/category-service";
import { HomepageService } from "src/services/homepage-service";
import { HomepageItem } from "src/services/params-type";
import "./styles.scss";
import NewsDetailModal, {
  NewsInfo,
} from "src/pages/personal-information/new-feed/news-detail-modal";

export enum HomepageSectionType {
  SUBCATEGORY = "subcategory",
  CATCH_AREA = "catch-area",
  PRODUCERS = "producers",
  RECIPLE = "reciple",
  DISTRIBUTOR = "distributor",
  FEATURED = "featured",
  NEWSFEED = "newsfeed",
}

export interface SectionDetailProps {
  title: string;
  sectionType: HomepageSectionType;
  setSectionDetail?: React.Dispatch<React.SetStateAction<SectionDetailProps | undefined>>;
}

const { useBreakpoint } = Grid;

const DISPLAY_LIMIT = 25;

const pinterestOrder = [
  "small",
  "medium",
  "small",
  "medium",
  "small",
  "medium",
  "large",
  "large",
  "small",
  "medium",
  "large",
  "large",
];

const SectionDetail = () => {
  const { sectionType } = useParams<{ sectionType: string }>();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const categoryService = new CategoryService();
  const homepageService = new HomepageService();

  const [selectedNews, setSelectedNews] = useState<NewsInfo>();

  let cardLimit = 2;
  if (breakpoint.md) cardLimit = 4;
  // if (breakpoint.xl) cardLimit = 4;

  const pinterestLength = pinterestOrder.length + 1;

  const goBack = () => {
    history.push(PATHS.default());
  };

  const getSectionData = () => {
    switch (sectionType) {
      case HomepageSectionType.CATCH_AREA:
        return homepageService.getCatchArea();
      case HomepageSectionType.PRODUCERS:
        return homepageService.getTierList(TIER.FACTORY);
      case HomepageSectionType.DISTRIBUTOR:
        return homepageService.getTierList(TIER.DISTRIBUTOR);
      case HomepageSectionType.RECIPLE:
        return homepageService.getIdealRecipe();
      case HomepageSectionType.FEATURED:
        return homepageService.getFeatureProduct();
      case HomepageSectionType.NEWSFEED:
        return homepageService.getNewsFeed();
      default:
        return categoryService.getSubCategories();
    }
  };

  const getTitle = () => {
    switch (sectionType) {
      case HomepageSectionType.CATCH_AREA:
        return "Catch Area";
      case HomepageSectionType.PRODUCERS:
        return "5 Star Producers";
      case HomepageSectionType.DISTRIBUTOR:
        return "Where to Buy";
      case HomepageSectionType.RECIPLE:
        return "Idea Recipe";
      case HomepageSectionType.FEATURED:
        return "Featured";
      case HomepageSectionType.NEWSFEED:
        return "News Feed";
      default:
        return "What's Fresh";
    }
  };

  const title = getTitle();

  const { data: sectionData, isLoading } = useQuery({
    queryFn: () => getSectionData(),
    select(data) {
      return get(data, "data", []) as HomepageItem[];
    },
  });

  if (isLoading || !Array.isArray(sectionData))
    return (
      <div className="homepage-content">
        <div style={{ padding: 0 }} className="section-detail">
          <Breadcrumb>
            <Breadcrumb.Item href={PATHS.default()}>Home</Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="section-header">
            <Space className="section-back" onClick={goBack}>
              <ArrowLeftIcon />
              <h1 className="section-title">{title}</h1>
              <Spinner />
            </Space>
          </div>
        </div>
      </div>
    );

  const renderSubcategoryList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list">
        {(sectionData || []).map((item, index) => (
          <Col span={24 / cardLimit} key={index}>
            <div
              className="section-item"
              onClick={() => history.push(`${PATHS.subcategoryDetail()}/${item?.id}`)}
            >
              <MediaView
                preview={false}
                className="item-img"
                data={{
                  link: item?.logo || "",
                  thumbnail: item?.thumbnail || "",
                  type: item?.type,
                }}
              />
              <div className="item-name">
                <OverflowTooltip title={item?.name}>
                  <span className="name">{item?.name}</span>
                </OverflowTooltip>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderRegionList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list">
        {(sectionData || []).map((item, index) => {
          const cardName = item?.name || "";
          return (
            <Col span={24 / cardLimit} key={index}>
              <div
                key={index}
                className="section-item"
                onClick={() => history.push(`${PATHS.regionDetail()}/${item.id}`)}
              >
                <MediaView
                  preview={false}
                  className="item-img"
                  data={{
                    link: item?.headingImage || "",
                    thumbnail: item?.thumbnail || "",
                    type: item?.type,
                  }}
                />
                <div className="item-name">
                  <OverflowTooltip title={cardName}>
                    <span className="name">{cardName || "No name"}</span>
                  </OverflowTooltip>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderRecipeList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list">
        {(sectionData || []).map((item: HomepageItem, index) => (
          <Col span={24 / cardLimit} key={index}>
            <div className="section-item recipe">
              <MediaView preview={true} className="item-img" data={item as IMedia} />
              <div className="item-name">
                <OverflowTooltip title={item?.name}>
                  <span className="name">{item?.name}</span>
                </OverflowTooltip>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderDistributorList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list">
        {sectionData.map((item, index) => {
          const srcImg = item.headingImage || item.logo || item.link || item.thumbnail || "";
          const firstMedia = (item?.groupMedia || [])?.[0];
          const imgLink = firstMedia?.thumbnail || firstMedia?.link || srcImg || DefaultRestaurant;

          return (
            <Col span={24 / cardLimit} key={index}>
              <div
                className={`section-item`}
                key={index}
                onClick={() => history.push(`${PATHS.restaurantDetail()}/${item.id}`)}
              >
                <MediaView
                  preview={false}
                  className="item-img"
                  data={{ link: imgLink, thumbnail: item?.thumbnail || "", type: item?.type }}
                />
                <div className="item-name">
                  <Avatar src={item?.logo} alt="" />
                  <div className="text-group">
                    <OverflowTooltip
                      title={(item?.name || "").length > DISPLAY_LIMIT && item?.name}
                    >
                      <span className="name">{item?.name || item?.group?.name || "No name"}</span>
                    </OverflowTooltip>
                    <OverflowTooltip
                      title={(item?.introduction || "").length > 40 && item?.introduction}
                    >
                      <span className="introduction">{item?.introduction || "No description"}</span>
                    </OverflowTooltip>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderFeaturedList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list newsfeed">
        {((Array.isArray(sectionData) && sectionData) || []).map((item: HomepageItem, index) => {
          return (
            <div
              className={`section-item hidden-name ${
                pinterestOrder[((index + 1) % pinterestLength) - 1]
              }`}
            >
              <MediaView preview={true} className="item-img" data={item as IMedia} />
            </div>
          );
        })}
      </Row>
    );
  };

  const renderNewsFeedList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list">
        {sectionData.map((item, index) => {
          const srcImg = item.headingImage || item.logo || item.link || item.thumbnail || "";
          const firstMedia = (item?.groupMedia || [])?.[0];
          const imgLink = firstMedia?.thumbnail || firstMedia?.link || srcImg || "";

          return (
            <Col span={24 / cardLimit} key={index}>
              <div
                className={`section-item`}
                key={index}
                onClick={() => setSelectedNews(item as NewsInfo)}
              >
                <MediaView
                  key={index}
                  preview={false}
                  className="item-img"
                  data={{ link: imgLink, thumbnail: item?.thumbnail || "", type: item?.type }}
                />
                <div className="item-name newsfeed">
                  <OverflowTooltip title={item?.title}>
                    <span className="name">{item?.title || "TBD"}</span>
                  </OverflowTooltip>
                  <div className="text-group">
                    <Avatar src={item?.group?.logo || DefaultImage} alt="" />
                    <OverflowTooltip title={item?.group?.name}>
                      <span className="name">{item?.group?.name || "TBD"}</span>
                    </OverflowTooltip>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderProducerList = () => {
    return (
      <Row gutter={[20, 20]} className="section-list producers">
        {(sectionData || []).map((item, index) => (
          <Col span={24 / (cardLimit + (breakpoint.xxl ? 4 : breakpoint.lg ? 2 : 0))} key={index}>
            <div
              className="section-item"
              onClick={() => history.push(`${PATHS.producerDetail()}/${item?.id}`)}
            >
              <MediaView
                preview={false}
                className="item-img"
                data={{
                  link: item?.logo || "",
                  thumbnail: item?.thumbnail || "",
                  type: item?.type,
                }}
              />
              <div className="item-name">
                <OverflowTooltip title={item?.name}>
                  <span className="name">{item?.name}</span>
                </OverflowTooltip>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderListItem = () => {
    if (sectionType === HomepageSectionType.SUBCATEGORY) return renderSubcategoryList();
    if (sectionType === HomepageSectionType.CATCH_AREA) return renderRegionList();
    if (sectionType === HomepageSectionType.RECIPLE) return renderRecipeList();
    if (sectionType === HomepageSectionType.DISTRIBUTOR) return renderDistributorList();
    if (sectionType === HomepageSectionType.FEATURED) return renderFeaturedList();
    if (sectionType === HomepageSectionType.NEWSFEED) return renderNewsFeedList();
    if (sectionType === HomepageSectionType.PRODUCERS) return renderProducerList();

    return (
      <Row gutter={[20, 20]} className="section-list">
        {((Array.isArray(sectionData) && sectionData) || []).map((item: HomepageItem, index) => (
          <Col span={24 / cardLimit} key={index}>
            <div className="section-item">
              <MediaView
                preview={true}
                className="item-img"
                data={{
                  link: item?.logo || "",
                  thumbnail: item?.thumbnail || "",
                  type: item?.type,
                }}
              />
              <div className="item-name">
                <OverflowTooltip title={item?.name}>
                  <span className="name">{item?.name}</span>
                </OverflowTooltip>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="homepage-content">
      <div style={{ padding: 0 }} className="section-detail">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={PATHS.default()}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="section-header">
          <Space className="section-back" onClick={goBack}>
            <ArrowLeftIcon />
            <h1 className="section-title">{title}</h1>
          </Space>
        </div>
        {renderListItem()}
      </div>

      <NewsDetailModal
        open={!!selectedNews}
        newsInfo={selectedNews}
        onCancel={() => setSelectedNews(undefined)}
      />
    </div>
  );
};

export default memo(SectionDetail);
