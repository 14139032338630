import { Avatar, Image, ModalProps, Space } from "antd";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { DefaultImage, DefaultVideo, PlayVideoThumbnailIcon } from "src/assets/icons";
import TierHubModal from "src/components/05.modal";
import { EPRODUCT_MEDIA_TYPE } from "src/constants";
import "./styles.scss";

export interface NewsInfo {
  description?: string;
  link: string;
  thumbnail?: string;
  title: string;
  type: number;
  group?: {
    logo: string;
    name: string;
  };
}

interface IProps extends ModalProps {
  newsInfo?: NewsInfo;
  hiddenGroupInfo?: boolean;
}

const NewsDetailModal: React.FC<IProps> = ({ newsInfo, hiddenGroupInfo = false, ...props }) => {
  const isVideoType = newsInfo?.type === EPRODUCT_MEDIA_TYPE.VIDEO;

  const [videoUrl, setVideoUrl] = useState("");

  return (
    <div>
      <TierHubModal width={484} {...props} title={newsInfo?.title} className="news-detail-modal">
        <div className="news-detail-content">
          <div className="news-img">
            <Image
              preview={isVideoType ? false : true}
              src={isVideoType ? newsInfo?.thumbnail : newsInfo?.link}
              alt="feed-back"
              fallback={isVideoType ? DefaultVideo : DefaultImage}
            />
            {isVideoType && (
              <div onClick={() => setVideoUrl(newsInfo?.link)}>
                <div className="overlay-video"></div>
                <div className="overlay-text">
                  <PlayVideoThumbnailIcon />
                </div>
              </div>
            )}
          </div>
          {!hiddenGroupInfo && (
            <Space className="news-group">
              <Avatar src={newsInfo?.group?.logo} alt="" />
              <span>{newsInfo?.group?.name}</span>
            </Space>
          )}
          {/* <h1 className="news-title">{newsInfo?.title}</h1> */}
          <p className="news-description">{newsInfo?.description}</p>
        </div>
      </TierHubModal>

      {videoUrl && (
        <TierHubModal
          className="newsfeed-modal"
          open={!!videoUrl}
          onCancel={() => setVideoUrl("")}
          closeIcon={<></>}
        >
          <ReactPlayer width={"100%"} stopOnUnmount controls url={videoUrl} />
        </TierHubModal>
      )}
    </div>
  );
};

export default NewsDetailModal;
