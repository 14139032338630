import QrcodeDecoder from "qrcode-decoder";

const useQRImageScanner = () => {
  const decodeQRFromBase64 = async (base64: string) => {
    const image = await createImageElement(base64);
    const qr = new QrcodeDecoder();
    const decodedData = await new Promise((resolve) => {
      setTimeout(async () => {
        try {
          const { data } = await qr.decodeFromImage(image);
          resolve(data);
        } catch (error) {
          resolve(null);
        }
      }, 300);
    });
    return decodedData as string;
  };

  const createImageElement = async (base64: string) => {
    const image = document.createElement("img");
    image.src = base64;
    image.width = 200;
    image.height = 200;
    image.style.visibility = "hidden";
    return image;
  };

  const convertBase64 = (file: any) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const decodeQRFromUploadedFile = async (file: any) => {
    try {
      const base64 = await convertBase64(file);
      const qrData = await decodeQRFromBase64(base64);
      return qrData;
    } catch (error) {
      return false;
    }
  };

  return { decodeQRFromUploadedFile, convertBase64 };
};

export default useQRImageScanner;
