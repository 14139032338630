// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-input {
  position: relative;
}
.autocomplete-input img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 25px;
  height: 25px;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/22.autocomplete/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAFF","sourcesContent":["@import \"src/assets/scss/variable.scss\";\n\n.autocomplete-input {\n  position: relative;\n\n  img {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    right: 15px;\n    width: 25px;\n    height: 25px;\n  }\n}\n\n.ant-select-single .ant-select-selector .ant-select-selection-placeholder {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
