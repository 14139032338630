import {
  HIDE_SEARCH_MODAL,
  SEARCH_PRODUCT_NAME,
  SHOW_SEARCH_MODAL,
} from "../constants/search-modal";

export interface SearchModalState {
  isShowSearchModal: boolean;
  search: string;
}
const initialState: SearchModalState = {
  isShowSearchModal: false,
  search: "",
};

export const searchModalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_SEARCH_MODAL:
      return {
        ...state,
        isShowSearchModal: true,
      };
    case HIDE_SEARCH_MODAL:
      return {
        ...state,
        isShowSearchModal: false,
      };
    case SEARCH_PRODUCT_NAME:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};
