import { UploadResponse } from "src/types";
import { HttpClient } from "./http-service";
import { AxiosResponse } from "axios";

interface Dict<T> {
  [key: string]: T;
  [key: number]: T;
}

export interface ChangeListener {
  (event: any): any;
}

export class ServiceBase extends HttpClient {
  private onChangeListeners: Dict<ChangeListener> = {};
  getDataResult = (response: AxiosResponse) => {
    const status = response?.data?.statusCode;
    const data = response?.data?.data;
    const meta = response.data?.meta;
    return { data, meta, status };
  };

  get = async (url: string, params?: any): Promise<any> => {
    const response = await this.axiosInstance.get(url, { params });
    return this.getDataResult(response);
  };

  put = async (url: string, data?: any): Promise<any> => {
    const response = await this.axiosInstance.put(url, data);
    return this.getDataResult(response);
  };

  patch = async (url: string, data: any): Promise<any> => {
    const response = await this.axiosInstance.patch(url, data);
    return this.getDataResult(response);
  };

  post = async (url: string, params: any): Promise<any> => {
    const response = await this.axiosInstance.post(url, params);
    return this.getDataResult(response);
  };

  delete = async (url: string, ids: [number]): Promise<any> => {
    const response = await this.axiosInstance.delete(`${url}`, { data: ids });
    return this.getDataResult(response);
  };

  delete2 = async (url: string, data: any): Promise<any> => {
    const response = await this.axiosInstance.delete(`${url}`, { data });
    return this.getDataResult(response);
  };

  deleteByUrl = async (url: string): Promise<any> => {
    const response = await this.axiosInstance.delete(url);
    return this.getDataResult(response);
  };

  update = async (url: string, id: number | undefined, params: any): Promise<any> => {
    const response = await this.axiosInstance.patch(`${url}/${id}`, params);
    return this.getDataResult(response);
  };

  subscribe(key: string, listener: ChangeListener) {
    if (this.onChangeListeners[key]) return;
    this.onChangeListeners[key] = listener;
  }

  unsubcribe(key: string) {
    delete this.onChangeListeners[key];
  }

  fire(data: any) {
    Object.values(this.onChangeListeners).forEach((listener) => listener(data));
  }

  uploadImage = async (file: File): Promise<UploadResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.axiosInstance.post("storage/upload", formData);
    return response.data;
  };
}
