import { Modal, ModalProps } from "antd";
import { FC } from "react";
import { CloseModaIcon } from "src/assets/icons";
import "./styles.scss";

interface Props extends ModalProps {
  customClass?: string;
}

const FilterModal: FC<Props> = (props) => {
  const { children, customClass } = props;
  return (
    <Modal
      {...props}
      className={`filter-modal ${customClass ? customClass : ""}`}
      closeIcon={<img src={CloseModaIcon} alt="close icon" />}
    >
      {children}
    </Modal>
  );
};

export default FilterModal;
