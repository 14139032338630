import "@reach/combobox/styles.css";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import { useEffect, useMemo, useState } from "react";
import { KEY_GOOGLE_MAP } from "src/constants/env";
import stateRegion from "./RegionAU/astralian-states.json";

import MarkerMap from "./MarkerMap";
import useWindowSize from "src/hooks/useWindowSize";
import "./styles.scss";

// const TILE_SIZE = 256;

const MapAustralia = (props: any) => {
  const { data, regionOptions, selectedRegions, changeSelectRegions } = props;
  const { isMobileAndTablet } = useWindowSize();
  const Australia = { lat: -25.032153603279006, lng: 134.778869108489 };
  // const [maps, setMaps] = useState<any>();
  const [selectedMarkerId, setSelectedMarkerId] = useState<number>();
  const [selectAllRegion, setSelectAllRegion] = useState(true);
  const [regionCode, setRegionCode] = useState<string[]>([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const center = useMemo(() => Australia, []);

  const regionOptionCodes = (regionOptions || [])?.map((item: any) => item?.regionCode);

  useEffect(() => {
    if (selectedRegions.length !== 0) setSelectAllRegion(false);
    setRegionCode(selectedRegions);
  }, [selectedRegions]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: KEY_GOOGLE_MAP,
    libraries: ["marker"],
    language: "en",
    region: "AU",
  });
  const containerStyle = {
    width: "100%",
    height: isMobileAndTablet ? "400px" : "800px",
  };

  // function project(latLng: google.maps.LatLng) {
  //   let siny = Math.sin((latLng.lat() * Math.PI) / 180);
  //   siny = Math.min(Math.max(siny, -0.9999), 0.9999);
  //   return new google.maps.Point(
  //     TILE_SIZE * (0.5 + latLng.lng() / 360),
  //     TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  //   );
  // }

  // Custom Markers Map
  function addMarkers() {
    // const data: any[] = [
    //   {
    //     id: 1,
    //     latitude: -33.865143,
    //     longitude: 151.2099,
    //     category: {
    //       parentCategory: {
    //         name: "Test",
    //         logo: "https://www.svgrepo.com/show/60393/fish.svg",
    //       },
    //     },
    //   },
    //   {
    //     id: 1,
    //     latitude: -32.865143,
    //     longitude: 149.2099,
    //     category: {
    //       parentCategory: {
    //         name: "Test",
    //         logo: "https://www.svgrepo.com/show/60393/fish.svg",
    //       },
    //     },
    //   },
    // ];

    const markers = data.map((value: any) => {
      return (
        <MarkerMap
          marker={value}
          selectedMarkerId={selectedMarkerId as number}
          setSelectedMarkerId={(name) => setSelectedMarkerId(name)}
        />
      );
    });
    return markers;
  }

  const convertDataRegion = stateRegion.features.map((region: any) => {
    const newRegion = region.geometry.coordinates.map((regions: any, index: number) => {
      const flatData = regions.flat(1).map((item: any) => {
        return { lat: item[1], lng: item[0], regionCode: region.properties.STATE_NAME };
      });
      return flatData;
    });
    return newRegion;
  });

  // function isNestedArray(arr: any) {
  //   for (let i = 0; i < arr.length; i++) {
  //     if (Array.isArray(arr[i])) {
  //       return true; // Nếu phần tử là một mảng, trả về true
  //     }
  //   }
  //   return false; // Nếu không có phần tử nào là một mảng, trả về false
  // }

  const renderColor = (code: string) => {
    if (regionCode.includes(code) || selectAllRegion) {
      return {
        strokeColor: "green",
        strokeOpacity: 0.3,
        strokeWeight: 2,
        fillColor: "green",
        fillOpacity: 0.3,
      };
    } else {
      return {
        strokeColor: "green",
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: "green",
        fillOpacity: 0,
      };
    }

    // switch (code) {
    //   case regionCode:
    //     return {
    //       strokeColor: "green",
    //       strokeOpacity: 0.3,
    //       strokeWeight: 2,
    //       fillColor: "green",
    //       fillOpacity: 0.3,
    //     };
    //   default:
    //     return {
    //       strokeColor: "green",
    //       strokeOpacity: 0,
    //       strokeWeight: 2,
    //       fillColor: "green",
    //       fillOpacity: 0,
    //     };
    // }
  };

  return (
    <div>
      {!isLoaded ? (
        <>loading...</>
      ) : (
        <>
          <GoogleMap
            zoom={3}
            center={center}
            mapContainerStyle={containerStyle}
            onClick={(e) => console.log("event", e)}
            // onLoad={(map) => {
            //   setMaps(map);
            // }}
            options={{
              gestureHandling: "greedy",
              zoomControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              minZoom: 3,
              maxZoom: 19,
              streetViewControl: false,
              rotateControl: false,
              scaleControl: false,
            }}
            // onZoomChanged={() => {
            //   const zoom = maps?.getZoom();
            //   const center = maps?.getCenter();
            //   const bounds = maps?.getBounds();
            //   const Bounds = {
            //     neLat: bounds?.getNorthEast()?.lat(),
            //     swlat: bounds?.getSouthWest()?.lat(),
            //     nelng: bounds?.getNorthEast()?.lng(),
            //     swlng: bounds?.getSouthWest()?.lng(),
            //   };
            //   console.log("zoom", zoom);
            //   console.log("Bounds", Bounds);
            //   console.log("center", center?.lat(), center?.lng());
            // }}
            // onDragEnd={() => {
            //   const bounds = maps?.getBounds();
            //   const center = maps?.getCenter();
            //   const Bounds = {
            //     neLat: bounds?.getNorthEast()?.lat(),
            //     swlat: bounds?.getSouthWest()?.lat(),
            //     nelng: bounds?.getNorthEast()?.lng(),
            //     swlng: bounds?.getSouthWest()?.lng(),
            //   };
            //   console.log("Bounds", Bounds);
            //   console.log("center", center?.lat(), center?.lng());
            // }}
          >
            {convertDataRegion.map((region, index) => {
              return (
                <Polygon
                  onClick={() => {
                    if (selectAllRegion) {
                      const newArr = (regionOptionCodes || []).filter(
                        (item: any) => item !== region.flat(2)[index].regionCode,
                      );
                      setRegionCode(newArr);
                      changeSelectRegions(newArr);
                      setSelectAllRegion(false);
                      return;
                    }
                    if (regionCode.includes(region.flat(2)[index].regionCode)) {
                      const newArr = (regionCode || []).filter(
                        (item) => item !== region.flat(2)[index].regionCode,
                      );
                      setRegionCode(newArr);
                      changeSelectRegions(newArr);
                    } else {
                      const newArr = [...regionCode, region.flat(2)[index].regionCode];
                      setRegionCode(newArr);
                      changeSelectRegions(newArr);
                    }
                  }}
                  // onMouseMove={() => console.log("onMouseMove", region.flat(2)[index].regionCode)}
                  paths={region}
                  options={renderColor(region.flat(2)[index].regionCode)}
                />
              );
            })}
            {addMarkers()}
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default MapAustralia;
