import { ServiceBase } from "./core/service-base";

export class CountryServices extends ServiceBase {
  getListContry = async (params: any) => {
    return this.get(`/countries`, params);
  };
  getDetailCountry = async (id: string) => {
    return this.get(`/countries/${id}`, {});
  };
  getRegionsCountry = async (code: string) => {
    return this.get(`/countries/regions/${code}`);
  };
  getAllRegion = async () => {
    return this.get(`/regions`);
  };

  getRegionDetail = async (params: any) => {
    return this.get(`/regions`, params);
  };
  getFishMarket = async (params: any) => {
    const response = await this.get("/fish-market", params);
    return response;
  };
}
