import { combineReducers } from "redux";
import { accountReducer } from "./reducers/account";
import { networkReducer } from "./reducers/network";
import { notificationReducer } from "./reducers/notification";
import { searchModalReducer } from "./reducers/search-modal";
import { themeReducer } from "./reducers/theme";
import { userReducer } from "./reducers/user";

const appReducer = combineReducers({
  networkIsWrong: networkReducer,
  currentAccount: accountReducer,
  user: userReducer,
  theme: themeReducer,
  notification: notificationReducer,
  searchModal: searchModalReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
