import BigNumber from "bignumber.js";

export const UPLOAD_TYPE_ALLOW = {
  SVG: "image/svg+xml",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  MP4: "video/mp4",
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const AustraliaCenter = { lat: -25.032153603279006, lng: 134.778869108489 };
export const HongkongCenter = { lat: 22.3220806286703, lng: 114.17072932448195 };

export const UPLOAD_SIZE_ALLOW = {
  twoMB: 2 * 1024 * 1024,
  fiveMB: 5 * 1024 * 1024,
  tenMB: 10 * 1024 * 1024,
};
export const DATE_FORMAT_PRODUCT = "YYYY/MM/DD";
export const LIMIT = 10;
export const GET_ALL = 10000000;
export const DEFAULT_MODAL_WIDTH = 528; // unit pixcel
export enum ORDER_STATUS {
  PENDING = 0,
  CONFIRMED = 1,
  DELIVERING = 2,
  DELIVERED = 3,
  CANCELLED = 4,
  REFUNDING = 5,
  REFUNDED = 6,
  SUCCEEDED = 7,
  SETTLED = 8,
  WAITING_FOR_PAYMENT = 9,
  REJECTED = 10,
  EXPIRED = 11,
  ON_GOING = 12,
  COMPLETED = 13,
  USER_PICKED_UP = 14,
  PREPARED = 15,
  PAID = 16,
  REFUND_REJECTED = 17,
}

export const getOrderStatus = (status: number) => {
  if (status === ORDER_STATUS.PENDING) {
    return "Pending";
  } else if (status === ORDER_STATUS.CONFIRMED) {
    return "Confirmed";
  } else if (status === ORDER_STATUS.DELIVERING) {
    return "Delivering";
  } else if (status === ORDER_STATUS.DELIVERED) {
    return "Delivered";
  } else if (status === ORDER_STATUS.CANCELLED) {
    return "Cancelled";
  } else if (status === ORDER_STATUS.REFUNDING) {
    return "Refunding";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.SUCCEEDED) {
    return "Succeeded";
  } else if (status === ORDER_STATUS.SETTLED) {
    return "Settled";
  } else if (status === ORDER_STATUS.WAITING_FOR_PAYMENT) {
    return "Waiting for payment";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.EXPIRED) {
    return "Expired";
  } else if (status === ORDER_STATUS.ON_GOING) {
    return "On going";
  } else if (status === ORDER_STATUS.COMPLETED) {
    return "Completed";
  } else if (status === ORDER_STATUS.USER_PICKED_UP) {
    return "User picked up";
  } else if (status === ORDER_STATUS.PREPARED) {
    return "Prepared";
  } else if (status === ORDER_STATUS.REFUND_REJECTED) {
    return "Refund refused";
  } else {
    return "Paid";
  }
};

export const format2Digit = (value: number | string) => {
  return new BigNumber(value || 0).toFormat(2, 1);
};

export enum MAIN_TAG {
  hot = "Hot",
  best_seller = "Best Seller",
  new = "New Dish",
}

export enum TIER {
  TIER_1 = "tier1",
  TIER_2 = "tier2",
  TIER_3 = "tier3",
  TIER_4 = "tier4",
  TIER_5 = "tier5",
  TIER_6 = "tier6",
}

export enum PAGE_STATE {
  NOT_FOUND = "404",
  SUCCESS = "success",
}

export enum NOT_FOUND_MSG {
  PAGE_BROKEN = "May be bigfoot has broken this page.",
  APPROVE_CAMERA = "You need to approve browser to use your camera",
  PERMISSION = "You have no permission to view/update information for this product",
  INVALID_QRCODE = "Invalid QR Code",
  TRANSACTION_FAILED = "Transaction to record to Blockchain failed!",
  APPROVAL_TIER_HUB = "You may need approval from TIER HUB team",
  APPROVAL_TIER_GROUP = "You may need approval from your TIER Group Manager",
}

export enum SUCCESS_MSG {
  TRANSACTION_SUCCESS = "Your record is stored on Blockchain",
}

export const formatCountdown = (seconds: number) => {
  return `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
};

export enum PERMISSION_STATE {
  DENIED = "denied",
  PROMPT = "prompt",
  GRANTED = "granted",
}

export enum EPRODUCT_GRADE {
  A = "A",
  B = "B",
  C = "C",
}

export enum ETRANSPORTATION {
  SEA = "Sea",
  AIR = "Air",
  RAIL = "Rail",
  ROAD = "Road",
}

export enum ECARGOSERVICE {
  LCL = "LCL",
  FCL = "FCL",
  LSE = "LSE",
}

export enum EPRODUCT_MEDIA_TYPE {
  PDF = 0,
  IMAGE = 1,
  VIDEO = 2,
}

export const EPRODUCT_GRADE_OPTIONS = [
  {
    value: EPRODUCT_GRADE.A,
    label: EPRODUCT_GRADE.A,
  },
  {
    value: EPRODUCT_GRADE.B,
    label: EPRODUCT_GRADE.B,
  },
  {
    value: EPRODUCT_GRADE.C,
    label: EPRODUCT_GRADE.C,
  },
];

export enum EPACKAGE_FORM {
  WHOLE = 1,
  FILLETED,
  STEAKS,
  CANNED,
}

export enum EPREPARATION_FORM {
  RAW = 1,
  COOKED,
  SMOKED,
  LIVE,
  FROZEN,
  DRIED,
  CANNED,
  PICKLED,
}

export const EPACKAGE_FORM_OPTIONS = [
  { value: EPACKAGE_FORM.WHOLE, label: "product.packageWhole" },
  { value: EPACKAGE_FORM.FILLETED, label: "product.packageFilleted" },
  { value: EPACKAGE_FORM.STEAKS, label: "product.packageSteaks" },
  { value: EPACKAGE_FORM.CANNED, label: "product.packageCanned" },
];

export const PREPARATION_OPTIONS = [
  {
    value: EPREPARATION_FORM.RAW,
    label: "product.preparationRaw",
  },
  {
    value: EPREPARATION_FORM.COOKED,
    label: "product.preparationCooked",
  },
  {
    value: EPREPARATION_FORM.SMOKED,
    label: "product.preparationSmoked",
  },
  {
    value: EPREPARATION_FORM.LIVE,
    label: "product.preparationLive",
  },
  {
    value: EPREPARATION_FORM.FROZEN,
    label: "product.preparationFrozen",
  },
  {
    value: EPREPARATION_FORM.DRIED,
    label: "product.preparationDried",
  },
  {
    value: EPREPARATION_FORM.CANNED,
    label: "product.preparationCanned",
  },
  {
    value: EPREPARATION_FORM.PICKLED,
    label: "product.preparationPickled",
  },
];

export const getPreparation = (value: number) => {
  switch (value) {
    case EPREPARATION_FORM.RAW:
      return "product.preparationRaw";
    case EPREPARATION_FORM.COOKED:
      return "product.preparationCooked";
    case EPREPARATION_FORM.SMOKED:
      return "product.preparationSmoked";
    case EPREPARATION_FORM.LIVE:
      return "product.preparationLive";
    case EPREPARATION_FORM.FROZEN:
      return "product.preparationFrozen";
    case EPREPARATION_FORM.DRIED:
      return "product.preparationDried";
    case EPREPARATION_FORM.CANNED:
      return "product.preparationCanned";
    case EPREPARATION_FORM.PICKLED:
      return "product.preparationPickled";
    default:
      return "--";
  }
};

export enum ETemplateDataType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE_TIME",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
}

export enum EProductStatus {
  NORMAL = 1,
  REPORTED,
}

export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;

export enum EGroupStatus {
  PENDING,
  APPROVABLE,
  APPROVED,
  REJECTED,
  REVOKED,
  OWNER_REVOKED,
}

export enum EQRCodeType {
  LOT = "lot",
  SUBLOT = "sub_lot",
  INDIVIDUAL = "individual",
}
