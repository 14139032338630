//eslint-disable jsx-a11y/anchor-is-valid
import { Drawer, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultOptionType } from "antd/lib/select";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import ButtonContained from "src/components/08.buttons/ButtonContained";
import Map from "src/components/19.react-google-map";
import { PATHS } from "src/constants/paths";
import useFetchCategoriesForFilter from "src/hooks/useFetchCategoriesForFilter";
import useWindowSize from "src/hooks/useWindowSize";
import { CategoryService } from "src/services/category-service";
import { CategoryLocation, ICategory } from "src/types/categories";
import { ECountry } from "src/types/country";
import FilterMap from "./filter-map";
import "./styles.scss";

export const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
];

export interface IMapData {
  minLat: number;
  maxLat: number;
  minLon: number;
  maxLon: number;
  zoomLevel: number;
}

export interface ICategoryOption extends ICategory {
  isSelected?: boolean;
  isHidden?: boolean;
}

export interface CustomSearchOptionType extends DefaultOptionType {
  customValue?: string;
}

const HomePage = () => {
  const categoryService = new CategoryService();
  const countryParam = new URLSearchParams(window.location.search).get("country");
  const history = useHistory();
  const { isMobile } = useWindowSize();

  // const [mapData, setMapData] = useState<IMapData>({
  //   minLat: 22.88453218916495,
  //   maxLat: 115.15874397558592,
  //   minLon: 21.7411386721362,
  //   maxLon: 113.21004402441405,
  //   zoomLevel: 1,
  // });

  const [categoryLocations, setCategoryLocations] = useState<CategoryLocation[]>([]);
  // const [selectedCountry, setSelectedCountry] = useState(ECountry.AU);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [openFilter, setOpenFilter] = useState(false);

  // const [mapFrame, setMapFrame] = useState<CategoriesFrame | null>({
  //   cenlat: "22.3220806286703",
  //   cenlon: "114.17072932448195",
  //   maxLat: null,
  //   maxLon: null,
  //   minLat: null,
  //   minLon: null,
  //   totalLocations: null,
  // });
  const {
    fishMarkets,
    categories,
    regionOptions,
    subCategories,
    searchSubcategoryValue,
    subCategoriesSuggestions,
    setSearchSubcategoryValue,
  } = useFetchCategoriesForFilter(countryParam as ECountry);

  const [form] = useForm();

  useEffect(() => {
    if (!(countryParam === ECountry.AU || countryParam === ECountry.HK))
      history.push(PATHS.map() + "?country=" + ECountry.AU);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryParam]);

  const categorySearchOptions = useMemo(() => {
    return subCategoriesSuggestions?.map((item) => ({
      value: item.id.toString(),
      label: (
        <div className="subcategory-option">
          <div className="subcategory-option__image">
            <img src={item.logo} alt="subcategory-option" />
          </div>
          <div className="subcategory-option__name">{item.name}</div>
        </div>
      ),
      customValue: item.name,
    }));
  }, [subCategoriesSuggestions]);

  // Filter data
  const getListCategoryMap = async (
    categoryIds: number[],
    subcategoryIds: number[],
    regionIds: any[],
    fishMarketIds: number[],
    selectedMap: ECountry,
  ) => {
    try {
      setSelectedRegions(regionIds);
      if (selectedMap === ECountry.HK) {
        const { data } = await categoryService.getMapLocationsCategory2({
          fishMarketId: fishMarketIds,
          subCategoryIds: subcategoryIds,
          categoryIds: categoryIds,
          region: regionIds,
        });
        setCategoryLocations(data);
      } else {
        const { data } = await categoryService.getMapLocationsCategory1({
          subCategoryIds: subcategoryIds,
          categoryIds: categoryIds,
          regionCode: regionIds,
        });
        setCategoryLocations(data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setOpenFilter(false);
  };

  // const getMapFrame = async () => {
  //   try {
  //     const selectedRegionCodes = regionOptions
  //       .filter((item) => item.isSelected)
  //       .map((item) => item.regionCode);

  //     const selectedCategoryIds = subCategoryOptionsForQuery
  //       .filter((item) => item.isSelected)
  //       .map((item) => item.id.toString());

  //     const frameParams: CategoriesFrameParams = {
  //       ...(selectedCategoryIds?.length || selectedSearchValue
  //         ? {
  //             subCategoryIds: selectedCategoryIds,
  //           }
  //         : {}),
  //       regionCode: selectedRegionCodes,
  //       parentCategoryId: selectedCategory.toString(),
  //       isHKMap: selectedCountry === ECountry.HK,
  //     };
  //     const { data: frameData } = await categoryService.getCategoriesFrame(frameParams);
  //     if (Number(frameData.totalLocations) > 0) {
  //       setMapFrame(frameData);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // const getRegion = async () => {
  //   try {
  //     const { data } = await countryService.getRegionsCountry(countryParam || "");
  //     setRegionOptions(data);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // useEffect(() => {
  //   getRegion();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [countryParam]);

  const renderFilter = () => (
    <FilterMap
      fishMarkets={fishMarkets}
      selectedCountry={countryParam as ECountry}
      categories={categories}
      subCategories={subCategories}
      regionOptions={regionOptions}
      onChangeFilter={getListCategoryMap}
      // highlightRegion={highlightRegion}
      highlightRegion={selectedRegions}
    />
  );

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  if (countryParam)
    if (!(countryParam === ECountry.AU || countryParam === ECountry.HK)) return null;

  return (
    <div className="home-wrapper">
      {isMobile && (
        <ButtonContained className="filter-button" onClick={handleOpenFilter}>
          Filter
        </ButtonContained>
      )}
      {isMobile ? (
        <Drawer
          open={openFilter}
          width={"354px"}
          placement="left"
          className="filter-drawer"
          onClose={handleCloseFilter}
        >
          {renderFilter()}
        </Drawer>
      ) : (
        renderFilter()
      )}
      <Form form={form} className="map-container">
        <div className="product-wrapper">
          <div className="category-list">
            <Map
              // mapFrame={mapFrame}
              // setMapData={setMapData}
              categoryLocations={categoryLocations}
              country={countryParam || ""}
              selectedCountry={countryParam as ECountry}
              changeSelectRegions={(regionCodes: string[]) => setSelectedRegions(regionCodes)}
              searchOptions={categorySearchOptions}
              searchSubcategoryValue={searchSubcategoryValue}
              setSearchSubcategoryValue={setSearchSubcategoryValue}
              // setSelectedSearchValue={setSelectedSearchValue}
              // handleResetFilterModal={handleResetFilterModal}
              regionOptions={regionOptions}
              selectedRegions={selectedRegions}
              form={form}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default HomePage;
