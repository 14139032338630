import {
  SET_ACCESS_TOKEN,
  SET_MY_TIER,
  // SET_USER_INFO_IAO,
  // SET_ROLE_AFFILIATE_IAO,
  SET_GROUP_ID_USER,
  SET_OWNER_ID,
  SET_LOGOUT_USERS,
  SET_PROFILE_STATUS,
  SET_OWNER,
  SET_USER_EMAIL,
  SET_USER_AVATAR,
  SET_USER_ID,
  SET_USER_NAME,
} from "../constants/user";

export const setUserEmailRedux = (payload: string) => {
  return {
    type: SET_USER_EMAIL,
    payload,
  };
};
export const setUserAvatarRedux = (payload: string) => {
  return {
    type: SET_USER_AVATAR,
    payload,
  };
};
export const setAccessTokenRedux = (payload: string) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload,
  };
};
export const setStoreMyTier = (payload: number) => {
  return {
    type: SET_MY_TIER,
    payload,
  };
};

export const setStoreGroupId = (payload: number) => {
  return {
    type: SET_GROUP_ID_USER,
    payload,
  };
};

export const setOwnerID = (payload: number | undefined) => {
  return {
    type: SET_OWNER_ID,
    payload,
  };
};

export const setLogout = () => {
  return {
    type: SET_LOGOUT_USERS,
    // payload,
  };
};

export const setProfileStatus = (payload: number) => {
  return {
    type: SET_PROFILE_STATUS,
    payload,
  };
};

export const setOwner = (payload: boolean) => {
  return {
    type: SET_OWNER,
    payload,
  };
};

export const setUserIdRedux = (payload: number) => {
  return {
    type: SET_USER_ID,
    payload,
  };
};

export const setUserNameRedux = (payload: string) => {
  return {
    type: SET_USER_NAME,
    payload,
  };
};
// export const setRoleAffiliateIAO = (payload: any) => {
//   return {
//     type: SET_ROLE_AFFILIATE_IAO,
//     payload,
//   };
// };
