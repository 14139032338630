import { AutoComplete, AutoCompleteProps } from "antd";
import { FC } from "react";
import { SearchIcon2 } from "src/assets/icons";
import TierHubInput from "../07.inputs/TierHubInput";
import "./styles.scss";
import { useTranslation } from "react-i18next";

interface Props extends AutoCompleteProps {}
const TierHubAutoComplete: FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <AutoComplete {...props}>
      <div className="autocomplete-input">
        <TierHubInput placeholder={t("map.placehoderSearch")} value={props.value} />
        <img src={SearchIcon2} alt="search" />
      </div>
    </AutoComplete>
  );
};

export default TierHubAutoComplete;
