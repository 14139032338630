import { Dropdown, Space, Spin } from "antd";
import { DefaultImage, NoDataProduct, NotificationIcon } from "src/assets/icons";
import { NotificationServices } from "src/services/notification-service";
import { useInfiniteQuery } from "@tanstack/react-query";
import { flattenDeep, get } from "lodash";
import { INotification } from "src/services/params-type";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./styles.scss";

dayjs.extend(relativeTime);

const EMPTY_VALUE = "TBD";
const PAGE_LIMIT = 10;

const NotificationDropdown = () => {
  const notificationServices = new NotificationServices();
  const listRef = useRef(null);

  const { fetchNextPage, hasNextPage, data, isLoading, refetch, remove } = useInfiniteQuery(
    ["notification"],
    (params) => {
      const { pageParam = 1 } = params;
      return notificationServices.getNotifications({ page: pageParam, limit: PAGE_LIMIT });
    },
    {
      getNextPageParam: (lastPage) => {
        if (!get(lastPage, "meta.hasNextPage", "")) {
          return undefined;
        }

        return Number(get(lastPage, "meta.currentPage", 0)) + 1;
      },
    },
  );

  const convertedData = get(data, "pages", []);
  const mapData = convertedData?.map((mapItem) => get(mapItem, "data"));
  const notificationList = flattenDeep(mapData) as INotification[];

  const renderEmptyNotification = () => {
    return (
      <div className="empty-container">
        <img src={NoDataProduct} alt="empty-icon" className="empty-icon" />
        <div className="empty-title">No Notification</div>
        <div className="empty-description">
          Notifications will appear in here <br /> if there is any update
        </div>
      </div>
    );
  };

  const renderNotificationList = () => {
    if (isLoading) return <Spin className="notification-loading" />;

    return notificationList.length ? (
      <div className="notification-list">
        <InfiniteScroll
          dataLength={notificationList.length}
          next={() => fetchNextPage()}
          hasMore={!!hasNextPage}
          loader={<Spin className="notification-loading" />}
          height={440}
          ref={listRef}
        >
          {(notificationList || []).map((item, index) => {
            const sendDate = dayjs(item.sendDate);
            const now = dayjs();

            const timeStr = sendDate.isSame(now, "day")
              ? sendDate.fromNow()
              : sendDate.format("DD/MM/YYYY");

            return (
              <div className="notification-item" key={index}>
                <Space align="start" size={12}>
                  <img
                    src={item?.imageUrl || DefaultImage}
                    alt="noti-img"
                    className="notification-img"
                  />
                  <div>
                    <Space>
                      <div className="notification-title">{item?.title || EMPTY_VALUE}</div>
                      <div className="notification-time">•</div>
                      <div className="notification-time">{timeStr}</div>
                    </Space>
                    <div className="notification-content">
                      {item?.notificationContent}&nbsp;&nbsp;
                      {item?.link && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={item?.link}
                          className="notification-link"
                        >
                          [Link]
                        </a>
                      )}
                    </div>
                  </div>
                </Space>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    ) : (
      renderEmptyNotification()
    );
  };

  return (
    <Dropdown
      dropdownRender={() => renderNotificationList()}
      overlayClassName="notification-dropdown"
      onOpenChange={(isOpen) => {
        if (isOpen) {
          remove();
          refetch();
        }
      }}
    >
      <NotificationIcon className="notification-icon" />
    </Dropdown>
  );
};

export default NotificationDropdown;
