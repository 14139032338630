import {
  SET_ACCESS_TOKEN,
  SET_MY_TIER,
  // SET_USER_INFO_IAO,
  // SET_ROLE_AFFILIATE_IAO,
  SET_GROUP_ID_USER,
  SET_OWNER_ID,
  SET_LOGOUT_USERS,
  SET_PROFILE_STATUS,
  SET_OWNER,
  SET_USER_AVATAR,
  SET_USER_EMAIL,
  SET_USER_ID,
  SET_USER_NAME,
} from "../constants/user";

export interface UserState {
  accessToken: string;
  myTier: number;
  groupId: string;
  ownerId: string;
  status: number;
  isOwner: boolean;
  id: number;
  avatar: string;
  email: string;
  name: string;
}

const initialState = {
  accessToken: "",
  myTier: "",
  // infoUserIAO: "",
  // roleAffiliate: "",
  groupId: "",
  ownerId: "",
  status: 0,
  isOwner: false,
  email: "",
  avatar: "",
  id: "",
  name: "",
};

export const userReducer = (state = initialState, action: any) => {
  // console.log('action',action);

  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_MY_TIER:
      return {
        ...state,
        myTier: action.payload,
      };
    case SET_USER_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    // case SET_USER_INFO_IAO:
    //   return {
    //     ...state,
    //     infoUserIAO: action.payload,
    //   };
    // case SET_ROLE_AFFILIATE_IAO:
    //   return {
    //     ...state,
    //     roleAffiliate: action.payload,
    //   };
    case SET_GROUP_ID_USER:
      return {
        ...state,
        groupId: action.payload,
      };
    case SET_OWNER_ID:
      return {
        ...state,
        ownerId: action.payload,
      };
    case SET_LOGOUT_USERS:
      return {
        ...initialState,
      };
    case SET_PROFILE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_OWNER:
      return {
        ...state,
        isOwner: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_USER_NAME:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return state;
  }
};
