import {
  HIDE_SEARCH_MODAL,
  SEARCH_PRODUCT_NAME,
  SHOW_SEARCH_MODAL,
} from "../constants/search-modal";

export const setShowSearchModal = () => {
  return {
    type: SHOW_SEARCH_MODAL,
  };
};
export const setHideSearchModal = () => {
  return {
    type: HIDE_SEARCH_MODAL,
  };
};

export const setSearchProductName = (search: string) => {
  return {
    type: SEARCH_PRODUCT_NAME,
    payload: search,
  };
};
