import { ServiceBase } from "./core/service-base";
import { INotificationSetting, PageParams } from "./params-type";
export class NotificationServices extends ServiceBase {
  // Implement method call API
  countUnread = async () => {
    return this.get("admin-notifications/unread");
  };

  listNotification = async (params: PageParams) => {
    return this.get("admin-notifications", params);
  };

  readNotification = async (id: number | string) => {
    return this.put(`admin-notifications/${id}/mark-as-read`, {});
  };

  registerNotification = async (token: string) => {
    return this.post(`/notification/register`, { token });
  };

  unsubcribeTopic = async (token: string) => {
    return this.post(`/notification/unsubscribe-all/${token}`, {});
  };

  updateNotificationSetting = async (params: INotificationSetting) => {
    return this.post("/users/notification-settings", params);
  };

  getNotifications = async (params: PageParams) => {
    return this.get("/notification", params);
  };
}
