import { Input, InputProps } from "antd";
import React from "react";
import "./styles.scss";

const KeyToVal = {
  key1: "default",
  key2: "focused",
  key3: "success",
  key4: "warning",
  key5: "error",
} as const;

type Keys = keyof typeof KeyToVal;
type Values = (typeof KeyToVal)[Keys];

export interface ITierHubInputProps extends InputProps {
  inputType?: Values;
}

export const TierHubInput: React.FC<ITierHubInputProps> = ({
  className,
  inputType = "default",
  ...props
}) => {
  return (
    <Input
      className={`tier-hub-input tier-hub-input--${inputType} ${className || className}`} 
      {...props}
    />
  );
};

export default TierHubInput;
