import { ServiceBase } from "./core/service-base";
import { CreateQRCodePayLoad, QrCodesParams } from "./params-type";

export class QrCodesService extends ServiceBase {
  getListQrCodes = async (params: QrCodesParams) => {
    const res = await this.get(`/qr-codes`, params);
    return res;
  };

  createQrCodes = async (body: CreateQRCodePayLoad) => {
    const res = await this.post(`/qr-codes/create-qr-codes`, body);
    return res.data;
  };

  deleteQrCodes = async (ids: any) => {
    const res = await this.delete("/qr-codes", ids);
    return res.data;
  };

  linkQrCodes = async (params: any) => {
    const res = await this.post("/qr-codes/link", params);
    return res.data;
  };
}

export const qrCodesService = new QrCodesService();
