export const PATHS = {
  default: () => "/home",
  map: () => "/map",
  merchant: () => "/merchant",
  consumer: () => "/consumer",
  notification: () => "/notification",
  categoryAndAttribute: () => "/category-and-attribute",
  login: () => "/login",
  productManagement: () => "/product-management",
  logisticInformation: () => "/logistic-information",
  updateProduct: () => "/update-product",
  emailTemplate: () => "/email-template",
  emailTemplateDetail: () => "/email-template/:id",
  forgotPassword: () => "/forgot-password",
  forgotSuccess: () => "/forgot-success",
  resetPassword: () => "/auth/reset-password",
  registration: () => "/registration",
  lastScan: () => "/last-scan",
  lastFeedback: () => "/last-feedback",
  categoryView: () => "/category-view/:id",
  listQRCode: () => "/QR-code-list",
  listApproveMembers: () => "/approve-member",
  listMembers: () => "/member-list",
  productView: () => "/product-view",
  detailTracking: () => "/detail-tracking",
  productDetail: () => "/product-view/:id/:stage",
  productCategory: () => "/product-category",
  productCategoryView: () => "/product-category/:id",
  passwordChanged: () => "/password-changed",
  // updateProduct: () => "/product/update",
  changePassword: () => "/home-admin/change-password",
  internalManagement: () => "/internal-management",
  createMember: () => "/internal-management/create-member",
  logisticManagement: () => "/logistic-management",
  setting: () => "/setting",
  personalInfo: () => "/setting/personal-info",
  businessInfo: () => "/setting/business-info",
  bankDetailInfo: () => "/setting/bank-detail-info",
  licenceInfo: () => "/setting/licence-info",
  introductionInfo: () => "/setting/introduction-info",
  newFeed: () => "/setting/new-feed",
  password: () => "/setting/password",
  payment: () => "/setting/payment",
  notificationSettings: () => "/setting/notification",
  languageSettings: () => "/setting/language",
  helpCenter: () => "/setting/help-center",
  deActivate: () => "deactivate-account",
  yourAdventure: () => "/your-adventure",
  qrScan: () => "/qr-scan",
  subcategoryDetail: () => "/subcategory-detail",
  regionDetail: () => "/region-detail",
  producerDetail: () => "/producer-detail",
  restaurantDetail: () => "/restaurant-detail",
};
