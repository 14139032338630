import { isUndefined } from "lodash";
import { useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.scss";

interface MultipleItemsSlickProps {
  children?: React.ReactNode;
  itemTarget?: number;
  isTriggerScrollTo?: boolean;
  slideToShow?: number;
  showDots?: boolean;
  className?: string;
}

export const MultipleItemsSlick = (props: MultipleItemsSlickProps) => {
  const { itemTarget, isTriggerScrollTo, slideToShow, showDots = false, className } = props;
  const carouselRef = useRef<Carousel>(null);
  const isInitialRender = useRef(true);
  const { children } = props;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: slideToShow || 9,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: slideToShow || 9,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: slideToShow || 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: slideToShow || 3,
    },
  };
  // const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  //   return (
  //     <div>
  //       <Button onClick={() => next()}>Next</Button>
  //       <Button onClick={() => previous()}>Pre</Button>
  //     </div>
  //   );
  // };

  const scrollToItem = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(index);
    }
  };

  useEffect(() => {
    // This code will run only after the first render
    if (!isInitialRender.current) {
      if (!isUndefined(itemTarget)) {
        scrollToItem(itemTarget);
      }
    } else {
      // Update the ref to indicate subsequent renders
      isInitialRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTriggerScrollTo]);

  return (
    <Carousel
      showDots={showDots}
      className={`${className} carousel`}
      responsive={responsive}
      slidesToSlide={1}
      ref={carouselRef}
      // infinite
    >
      {children}
    </Carousel>
  );
};
