export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_MY_TIER = "SET_MY_TIER";
// export const SET_USER_INFO_IAO = 'SET_USER_INFO_IAO';
// export const SET_ROLE_AFFILIATE_IAO = 'SET_ROLE_AFFILIATE_IAO';
export const SET_GROUP_ID_USER = "SET_GROUP_ID_USER";
export const SET_OWNER_ID = "SET_OWNER_ID";
export const SET_LOGOUT_USERS = "SET_LOGOUT_USERS";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS";
export const SET_OWNER = "SET_OWNER";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_USER_AVATAR = "SET_USER_AVATAR";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_NAME = "SET_USER_NAME";
