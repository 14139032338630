import { Button, Col, Row } from "antd";
import { useHistory } from "react-router";
import { SuccessIcon, TierhubLogo } from "src/assets/icons";
import { PATHS } from "src/constants/paths";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const PasswordChanged = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const onBackToLogin = () => {
    history.push(PATHS.login());
  };

  return (
    <Row className="login-page password-changed">
      <Col span={12} className="banner-img"></Col>
      <Col span={12} className="login-area">
        <div className="login-page__wrapperLogo">
          <img src={TierhubLogo} alt="login-background" />
          <div className="ver-divider"></div>
          <p>{t("slogan")}</p>
        </div>
        <div className="login-page__form">
          <img src={SuccessIcon} alt="success" />
          <div className="change-password-message">
            <h1>{t("auth.passwordChanged")}</h1>
            <p>{t("auth.youMayLoginWithNewPassword")}</p>
          </div>
          <Button
            onClick={onBackToLogin}
            size="large"
            type="primary"
            className="back-btn primary-btn"
          >
            {t("auth.backToLogin")}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default PasswordChanged;
