// import { UploadFile } from "antd/es/upload";
// import { UploadChangeParam } from "antd/lib/upload";
import { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { QRBorder } from "src/assets/icons";
import { toastMS } from "src/components/28.toast-message";
import { PERMISSION_STATE } from "src/constants";
import { TIER } from "src/constants/login";
import { PATHS } from "src/constants/paths";
// import useQRImageScanner from "src/hooks/useQRImageScanner";
import { productManagementService } from "src/services/product-management";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import { hasStorageJwtToken } from "src/helpers/storage";
const QRScan = () => {
  const [isDeniedPermisstion, setIsDeniedPermission] = useState(false);
  const isLogined = hasStorageJwtToken();
  const user = useSelector((state: any) => state.user);
  // const { decodeQRFromUploadedFile } = useQRImageScanner();
  const history = useHistory();

  const { t } = useTranslation();

  const checkCameraDenied = async () => {
    const permission = await navigator.permissions.query({ name: "camera" as PermissionName });
    if (permission.state === PERMISSION_STATE.DENIED) {
      setIsDeniedPermission(true);
    }
  };

  useEffect(() => {
    checkCameraDenied();
  }, []);

  const handleScanQr = async (result: any) => {
    try {
      if (result) {
        let QR_URL: string;
        if (result.includes("/")) {
          const segments = result.split("/");
          const productId = segments[segments.length - 1];
          QR_URL = productId;
        } else {
          QR_URL = result;
        }
        if (user?.myTier === TIER.CUSTOMER) {
          await productManagementService.getProductByQrCode(QR_URL, { isScan: true });
          history.push(`${PATHS.productView()}/${QR_URL}`);
          return;
        }

        if (isLogined) history.push(`${PATHS.productManagement()}/${QR_URL}`);
        else history.push(`${PATHS.productView()}/${QR_URL}`);
      }
    } catch (error) {}
  };

  const errScan = (err: any) => {
    toastMS("error", t("msg.noScanResultsFromUploadedImage"));
  };

  // const onNewScanResult = async (decodedText: any) => {
  //   try {
  //     if (decodedText) {
  //       if (user?.myTier === TIER.CUSTOMER) {
  //         await productManagementService.getProductByQrCode(decodedText, { isScan: true });
  //       }
  //       history.push(`${PATHS.productManagement()}/${decodedText}`);
  //     } else {
  //       TierToast.error("No scan results from uploaded image");
  //     }
  //   } catch (error) {}
  // };

  // const qrCodeErrorCallback = (errorMessage: any) => {
  //   TierToast.error("No scan results from uploaded image");
  // };

  useEffect(() => {
    const button = document.getElementById("html5-qrcode-button-file-selection");
    if (button) {
      button.textContent = t("scan.scanQRCodeFromImage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const uploadFile = async (info: UploadChangeParam<UploadFile<any>>) => {
  //   try {
  //     if (
  //       info?.file.originFileObj &&
  //       (info.file.status === "done" || info.file.status === "error")
  //     ) {
  //       const decodedQR = await decodeQRFromUploadedFile(info?.file.originFileObj);
  //       if (decodedQR) {
  //         let QR_URL: string;
  //         if (decodedQR.includes("/")) {
  //           const segments = decodedQR.split("/");
  //           const productId = segments[segments.length - 1];
  //           QR_URL = productId;
  //         } else {
  //           QR_URL = decodedQR;
  //         }
  //         if (user?.myTier === TIER.CUSTOMER) {
  //           await productManagementService.getProductByQrCode(QR_URL, { isScan: true });
  //         }
  //         if (isLogined) history.push(`${PATHS.productManagement()}/${QR_URL}`);
  //         else history.push(`${PATHS.productView()}/${QR_URL}`);
  //       } else {
  //         toastMS("error", t("msg.noScanResultsFromUploadedImage"));
  //       }
  //     }
  //   } catch (error) {
  //     console.log("err", error);
  //   }
  // };

  return (
    <div className="qr-scan-wrapper">
      <div className="qr-code-container">
        <QrReader
          onScan={handleScanQr}
          onError={() => errScan}
          delay={1000}
          style={{ width: "250px" }}
          facingMode={"environment"}
        />
        <div className="qr-code-border top-left">
          <img src={QRBorder} alt="border" />
        </div>
        <div className="qr-code-border top-right">
          <img src={QRBorder} alt="border" />
        </div>
        <div className="qr-code-border bottom-left">
          <img src={QRBorder} alt="border" />
        </div>
        <div className="qr-code-border bottom-right">
          <img src={QRBorder} alt="border" />
        </div>
      </div>
      {/* <Upload onChange={uploadFile} showUploadList={false}>
        <ButtonContained className="btn-upload-image">
          {t("scan.scanQRCodeFromImage")}
        </ButtonContained>
      </Upload> */}

      {/* <div className="qr-upload">
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          supportedScanTypes={[1]}
          formatsToSupport={formatQrcodeFormats}
          qrCodeSuccessCallback={onNewScanResult}
          qrCodeErrorCallback={qrCodeErrorCallback}
        />
      </div> */}

      {isDeniedPermisstion && (
        <p className="warning-qr-scan">{t("scan.ApproveBrowserToUseYourCamera")}</p>
      )}
    </div>
  );
};

export default QRScan;
