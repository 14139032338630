export interface CountryData {
  id: number;
  name: string;
  code: string;
  dialCode: string;
}

export enum ECountry {
  HK = "HK",
  AU = "AU",
}

export interface RegionsData {
  id: number;
  name: string;
  headingImage: string;
  country: string;
  countryCode: string;
  logo: string;
  region: string;
  regionCode: string;
}
