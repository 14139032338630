import { Tier1NewIcon, Tier2NewIcon, Tier3NewIcon, Tier5_0NewIcon, Tier5NewIcon, Tier6NewIcon } from "src/assets/icons";

export const STEPS_LOGIN = {
  NOT_LOGIN: 0,
  WAITTING_SEND_CODE: 1,
  CODE_SENDED: 2,
  EXISTED_ACCOUNT: 3,
};

export const REGISTRATION = {
  SELECT_TIER: 0,
  REGISTRATION_TIER: 1,
};

export const STEPS_REGISTRATION = {
  STEP_1: 0,
  STEP_2: 1,
  STEP_3: 2,
  STEP_4: 3,
  STEP_5: 4,
};
export enum LOGIN_METHOD {
  NORMAL = "Normal",
  APPLE = "Apple",
  GOOGLE = "Google",
  MS = "Microsoft",
}

export enum USER_STATUS {
  PENDING = 0,
  APPROVABLE = 1,
  APPROVED = 2,
  REJECTED = 3,
  REVOKED = 4,
  DEACTIVATED = 5,
}

export enum TIER {
  HARVESTER = 1,
  LOGISTIC_PARTNER = 2,
  FACTORY = 3,
  DISTRIBUTOR = 4,
  RESTAURANT = 4,
  CUSTOMER = 5,
}

export enum USER_GENDER {
  MALE = 0,
  FEMALE = 1,
  OTHER = 2,
}

export const getGender = (number: number | string) => {
  if (number === USER_GENDER.MALE) {
    return "Male";
  } else if (number === USER_GENDER.FEMALE) {
    return "Female";
  } else if (number === USER_GENDER.OTHER) {
    return "Other";
  } else {
    return "TBD";
  }
};

export const GenderOptions = [
  { value: "", label: "All" },
  { value: USER_GENDER.MALE, label: "Male" },
  { value: USER_GENDER.FEMALE, label: "Female" },
  { value: USER_GENDER.OTHER, label: "Other" },
];

export enum LICENSE_TYPE {
  IMAGE = 1,
  DOCS = 2,
}

export const tiers = [
  {
    key: TIER.HARVESTER,
    tier: TIER.HARVESTER,
    name: "Fishing Co.",
    image: Tier1NewIcon,
  },
  {
    key: TIER.LOGISTIC_PARTNER,
    tier: TIER.LOGISTIC_PARTNER,
    name: "Logistics Partner",
    image: Tier2NewIcon,
  },
  {
    key: TIER.FACTORY,
    tier: TIER.FACTORY,
    name: "Factory",
    image: Tier3NewIcon,
  },
  {
    key: TIER.DISTRIBUTOR,
    tier: TIER.DISTRIBUTOR,
    name: "Distributor",
    image: Tier5_0NewIcon,
  },
  {
    key: TIER.RESTAURANT,
    tier: TIER.RESTAURANT,
    name: "Restaurant",
    image: Tier5NewIcon,
  },
  {
    key: TIER.CUSTOMER,
    tier: TIER.CUSTOMER,
    name: "Foodie",
    image: Tier6NewIcon,
  },
];

export enum ERROR_CODE {
  WRONG_EMAIL_OR_PASS = "WRONG_EMAIL_OR_PASS",
  BLANK_PASSWORD = "BLANK_PASSWORD",
}

export const renderErrorMsg = (errorCode: string) => {
  switch (errorCode) {
    case "WRONG_EMAIL_OR_PASS":
      return "msg.incorrectEmailOrPassword";
    case "NOT_SIGNUP_WITH_PASSWORD":
      return "msg.thisAccountDoesn'tSignUpWithPassword";
    case "EXISTED_EMAIL_USER":
      return "msg.existedEmail";
    case "USER_WAITING_APPROVE":
      return "msg.accountNotYetVerified";
    case "USER_REVOKED":
      return "msg.accountRevokedByAdmin";
    case "INVALID_VERIFICATION_CODE":
      return "msg.invalidVerificationCode";
    case ERROR_CODE.BLANK_PASSWORD:
      return "msg.pleaseEnterPassword";
    case "REGISTERED_WITH_OTHER_METHOD":
      return "msg.thisAccountHasBeenRegisteredWith";
    case "RESOURCE_REQUIRE_APPROVED_USER":
      return "msg.waitAdminApprove";
    case "EXISTED_LINK_ACCOUNT":
      return "msg.existdLinkAccount";
    default:
      return "msg.loginFailed";
  }
};

export enum VERIFY_EMAIL {
  INVALIDATED_CODE = "1",
  NOT_EXISTED_EMAIL = "2",
}

export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PHONE_PATTERN = /^[0-9]{4,15}$/;
export const BSB_PATTERN = /^[0-9]{6}$/;
// export const PASSWORD_PATTERN =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/;
export const PASSWORD_PATTERN = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[^ ]+$/;
export const ACC_NUMBER_PATTERN = /^[0-9]{1,30}$/;

export const renderMsg = (verifyEmailStatus: string) => {
  switch (verifyEmailStatus) {
    case VERIFY_EMAIL.INVALIDATED_CODE:
      return "msg.invalidVerificationCode";
    case VERIFY_EMAIL.NOT_EXISTED_EMAIL:
      return "msg.thatEmailDoesNotExist";
    default:
      return "";
  }
};
