import { LogoutOutlined } from "@ant-design/icons";
import {
  MenuFlagIcon,
  MenuHomeIcon,
  MenuInternalIcon,
  MenuLogisticIcon,
  MenuProductIcon,
  MenuQrIcon,
  MenuSettingIcon,
  MenuSubscriptionIcon,
} from "src/assets/icons";
import { ECountry } from "src/types/country";

export const MENUS_KEY = {
  HOME: "home",
  MAP_AU: ECountry.AU,
  MAP_HK: ECountry.HK,
  PRODUCT_CATEGORY: "product-category",
  QR_CODE_LIST: "QR-code-list",
  GROUP_MANAGEMENT: "group-management",
  INTERNAL_MANAGEMENT: "internal-management",
  LOGISTIC_MANAGEMENT: "logistic-management",
  SUBSCRIPTION: "subscription",
  EMAIL_TEMPLATE: "email-template",
  NOTIFICATION: "notification",
  SETTING: "setting",
  ADVENTURE: "your-adventure",
};

export const MENUS_ITEM = {
  // Menu User
  HOME: {
    key: "home",
    label: "tab.home",
    level: 1,
    icon: <MenuHomeIcon />,
  },
  MAP_AU: {
    key: ECountry.AU,
    label: "Australia Distribution map",
    level: 1,
    icon: <MenuQrIcon />,
  },
  MAP_HK: {
    key: ECountry.HK,
    label: "Hong Kong Price Map",
    level: 1,
    icon: <MenuQrIcon />,
  },
  PRODUCT_CATEGORY: {
    key: "product-category",
    label: "tab.productCategory",
    level: 1,
    icon: <MenuProductIcon />,
    children: [],
  },
  QR_CODE_LIST: {
    key: "QR-code-list",
    label: "tab.qrCodeList",
    level: 1,
    icon: <MenuQrIcon />,
  },
  INTERNAL_MANAGEMENT: {
    key: "internal-management",
    label: "tab.internalManagement",
    level: 1,
    icon: <MenuInternalIcon />,
  },
  LOGISTIC_MANAGEMENT: {
    key: "logistic-management",
    label: "tab.logisticManagement",
    level: 1,
    icon: <MenuLogisticIcon />,
  },
  SUBSCRIPTION: {
    key: "subscription",
    label: "tab.subscription",
    level: 1,
    icon: <MenuSubscriptionIcon />,
  },
  LOGOUT: {
    key: "logout",
    label: "tab.logout",
    level: 1,
    icon: <LogoutOutlined />,
  },
  SETTING: {
    key: "setting",
    label: "tab.setting",
    level: 1,
    icon: <MenuSettingIcon />,
  },
  ADVENTURE: {
    key: "your-adventure",
    label: "tab.advanture",
    level: 1,
    icon: <MenuFlagIcon />,
  },
};

export const DEEP_MENUS = [
  {
    name: "tab.home",
    key: "home",
    children: [],
  },
  {
    name: "tab.map",
    key: "map",
    children: [],
  },
  {
    name: "tab.productCategory",
    key: "product-category",
    breadcrumb: [""],
    children: [
      { key: "fish", label: "tab.productCategoryFish", path: "fish", level: 2 },
      { key: "octopus", label: "tab.productCategoryOctopus", path: "octopus", level: 2 },
      { key: "crabs", label: "tab.internalManagement", path: "crabs", level: 2 },
    ],
  },
  {
    name: "tab.qrCodeList",
    key: "list-qr-code",
    children: [],
  },
  {
    name: "tab.internalManagement",
    key: "internal-management",
    children: [],
  },
  {
    name: "tab.logisticManagement",
    key: "logistic-management",
    children: [],
  },
  {
    name: "tab.groupManagement",
    key: "group-management",
    breadcrumb: [""],
    children: [
      { key: "approve-member", label: "tab.approveMember", path: "approve-member", level: 2 },
      { key: "member-list", label: "tab.memberList", path: "member-list", level: 2 },
      {
        key: "group-information",
        label: "tab.groupInformation",
        path: "group-information",
        level: 2,
      },
    ],
  },
  {
    name: "tab.subscription",
    key: "subscription",
    children: [],
  },
];

export const MenuItemKey = {
  HOME: "home",
  MAP_AU: ECountry.AU,
  MAP_HK: ECountry.HK,
  PRODUCT_CATEGORY: "product-category",
  QR_CODE_LIST: "QR-code-list",
  INTERNAL_MANAGEMENT: "internal-management",
  LOGISTIC_MANAGEMENT: "logistic-management",
  SUBSCRIPTION: "subscription",
  LOGOUT: "logout",
};

export const MenuItemsForAnonymouseUser = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
];

export const MenuItemsForTier1 = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
  MenuItemKey.LOGISTIC_MANAGEMENT,
  MenuItemKey.INTERNAL_MANAGEMENT,
];

export const MenuItemsForTier1Employee = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
  MenuItemKey.LOGISTIC_MANAGEMENT,
];

export const MenuItemsForTier245Owner = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
  MenuItemKey.INTERNAL_MANAGEMENT,
];

export const MenuItemsForTier245Employee = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
];

export const MenuItemsForTier3Owner = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
  MenuItemKey.LOGISTIC_MANAGEMENT,
  MenuItemKey.INTERNAL_MANAGEMENT,
];

export const MenuItemsForTier3Employee = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
  MenuItemKey.QR_CODE_LIST,
  MenuItemKey.LOGISTIC_MANAGEMENT,
];

export const MenuItemsForTier6 = [
  MenuItemKey.HOME,
  MenuItemKey.MAP_AU,
  MenuItemKey.MAP_HK,
  MenuItemKey.PRODUCT_CATEGORY,
];
