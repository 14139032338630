import { getApp, getApps, initializeApp } from "firebase/app";
import { deleteToken, getMessaging, getToken, Messaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "tierhub-mobile.firebaseapp.com",
  projectId: "tierhub-mobile",
  storageBucket: "tierhub-mobile.appspot.com",
  messagingSenderId: "372504023753",
  appId: "1:372504023753:web:8b1c83686cd8e158913318",
};

const isBrowserSupported = () => {
  return "Notification" in window && "serviceWorker" in navigator && "indexedDB" in window;
};

let app = null;
export let messaging: Messaging | null = null;

if (isBrowserSupported()) {
  app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
  messaging = getMessaging(app);
} else {
  console.warn("This browser doesn't support the required APIs for Firebase.");
}

export const generateTokenFirebase = async () => {
  if (!messaging) {
    console.warn("Firebase messaging is not initialized due to unsupported browser.");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      console.log("token: ", token);
      return token;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const removeTokenFirebase = async () => {
  if (!messaging) {
    console.warn("Firebase messaging is not initialized due to unsupported browser.");
    return;
  }

  try {
    await deleteToken(messaging);
    console.log("Delete token successfully");
  } catch (error) {
    console.log("Delete token failed");
  }
};
