import { LogLevel } from "@azure/msal-browser";
import { CLIENT_ID_MS, TENANT_MS } from "src/constants/env";

export const config_login_microsoft = {
  auth: {
    clientId: CLIENT_ID_MS,
    // redirectUri: `${REDIRECT_URI}/login`,
    authority: `https://login.microsoftonline.com/${TENANT_MS}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    temporaryCacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
