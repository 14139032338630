import { formatQueryParam } from "src/helpers";
import {
  CategoryLocation,
  SubCategoriesAtSamePosition,
  CategoriesFrame,
} from "src/types/categories";
import { ServiceBase } from "./core/service-base";
import {
  CategoriesFrameParams,
  CategoryLocationsParams,
  CategoryParams,
  SubCategoriesAtSamePositionParams,
} from "./params-type";

export class CategoryService extends ServiceBase {
  getCategories = async (params?: CategoryParams) => {
    return this.get("/categories", params);
  };
  getSubCategories = async (params?: CategoryParams) => {
    return this.get("/categories/sub-categories", params);
  };
  getCategoriesTree = async (params?: CategoryParams): Promise<CategoryItem[]> => {
    const response = await this.get("/categories/tree", params);
    return response.data;
  };

  getCategoriesById = async (id: string | number): Promise<CategoryItem> => {
    const response = await this.get(`/categories/${id}`);
    return response.data;
  };

  getMapLocationsCategory2 = async (
    params: CategoryLocationsParams,
  ): Promise<{ data: CategoryLocation[] }> => {
    return this.get(`/fish-market/hk-map?` + formatQueryParam(params));
  };

  getMapLocationsCategory1 = async (
    params: CategoryLocationsParams,
  ): Promise<{ data: CategoryLocation[] }> => {
    return this.get(`/categories/aus-map?` + formatQueryParam(params));
  };

  getMapLocationsCategory = async (params: CategoryLocationsParams) => {
    return this.get(`/categories/map/locations`, params);
  };

  getSubCategoriesAtSamePosition = async (
    params: SubCategoriesAtSamePositionParams,
    parentId: number,
  ): Promise<{ data: { data: SubCategoriesAtSamePosition[]; meta: any } }> => {
    return this.get(`/categories/sub-categories/${parentId}`, params);
  };

  getCategoriesFrame = async (
    params: CategoriesFrameParams,
  ): Promise<{ data: CategoriesFrame }> => {
    return this.get(`/categories/map/frame?` + formatQueryParam(params));
  };
}
