// 404

export { default as BscNetworkIcon } from "./bsc-network-icon.svg";
// Modal
export { default as CloseModalIcon } from "./close-icon.svg";
export { default as NotFoundPage } from "./common/404.png";
export { default as OceanBanner } from "./common/ocean-banner.png";
export { default as CameraLogo } from "./common/camera-logo.png";
export { default as ImageDefault } from "./common/default.jpg";
export { default as ImageDefaultProductView } from "./common/default-image-product-view.png";
export { default as SuccessLogo } from "./common/success-logo.png";
export { default as FailedLogo } from "./common/failed-logo.png";
export { default as LoginBackground } from "./common/bg-login.png";
export { default as Success } from "./common/success.png";
export { default as LogoMenu } from "./common/logo-menu.svg";
export { default as LogoUpdateProduct } from "./common/logo-update-product.svg";
export { default as Logo } from "./common/logo.svg";
export { default as LoadingSpinner } from "./common/loading-spinner.svg";
export { default as GalleryUpload } from "./common/gallery-upload.svg";
export { default as QRBorder } from "./common/QR-border.svg";
export { default as ThumbnailVideo } from "./common/thumbnail-video.jpg";
export { default as OnlyLogo } from "./common/only_Logo.svg";
export { default as MetamaskError } from "./metamask-error.svg";
export { default as MetamaskIcon } from "./metamask-icon.svg";
export { default as WalletIcon } from "./wallet-icon.svg";
export { default as ApproveIcon } from "./approve.svg";
export { default as RejectIcon } from "./reject.svg";
export { default as UploadImg } from "./common/icon-upload-img.svg";
export { default as FlagIcon } from "./flag.svg";
export { default as UploadIcon } from "./UploadFileIcon.svg";
export { default as AddFieldIcon } from "./plus.svg";
export { default as AddIcon } from "./add-icon.svg";
export { default as TrashIcon } from "./add-icon.svg";
export { default as TrashRedIcon } from "./trash-red-icon.svg";
export { default as BaseStep } from "./base-step.svg";
export { default as ActiveStep } from "./active-step.svg";
export { default as PassStep } from "./pass-step.svg";
export { default as AvatarIcon } from "./avatar-icon.svg";
export { default as TierhubLogo } from "./common/tierhub-logo.svg";
export { default as EmailIcon } from "./common/email-icon.svg";
export { default as PasswordIcon } from "./common/password-icon.svg";
export { default as BackCycle } from "./common/back-cycle.svg";
export { default as CloseCircle } from "./common/close-circle.svg";
export { default as CloseCircleVideoIcon } from "./common/close-circle-video.svg";
export { default as IconCalendar } from "./common/icon-calendar.svg";
export { default as IconUpload } from "./common/icon-upload.svg";
export { default as NoDataProduct } from "./no-product.svg";
export { default as RedFlag } from "./common/red-flag.svg";
export { default as Banner } from "./common/imagebanner.png";
export { default as GoogleIcon } from "./google.svg";
export { default as MicrosoftIcon } from "./microsoft.jpg";
export { default as AppleIcon } from "./apple.svg";
export { default as SuccessIcon } from "./success_img.svg";
export { default as Icon404 } from "./404.svg";
export { default as ImgTier } from "./common/img-tier.png";
export { default as Fish } from "./fish.svg";
export { default as SearchIcon } from "./search.svg";
export { default as Tier1Icon } from "./tier1.svg";
export { default as Tier2Icon } from "./tier2.svg";
export { default as Tier3Icon } from "./tier3.svg";
export { default as Tier5Icon } from "./tier5.svg";
export { default as Tier6Icon } from "./tier6.svg";
export { default as Tier1NewIcon } from "./tier1-new.svg";
export { default as Tier2NewIcon } from "./tier2-new.svg";
export { default as Tier3NewIcon } from "./tier3-new.svg";
export { default as Tier5_0NewIcon } from "./tier5-new.svg";
export { default as Tier5NewIcon } from "./tier5.1-new.svg";
export { default as Tier6NewIcon } from "./tier6-new.svg";
export { ReactComponent as ArrowLeftIcon } from "./common/arrow-left.svg";
export { ReactComponent as ArrowRightIcon } from "./common/arrow-right.svg";
export { ReactComponent as CornerUpRightIcon } from "./common/corner-up-right.svg";
export { ReactComponent as GridTableIcon } from "./common/grid-table.svg";
export { default as DefaultUserAvatarIcon } from "./common/default-user-avatar.svg";
export { default as MarkProductViewIcon } from "./common/mark-product-view.svg";
export { ReactComponent as PlayVideoThumbnailIcon } from "./common/play-video-thumbnail.svg";
export { ReactComponent as FlagReportIcon } from "./common/flag.svg";
export { ReactComponent as NotificationIcon } from "./common/notification-ic.svg";

// membership

export { default as Bronze } from "./member-ship/bronze.svg";
export { default as Silver } from "./member-ship/silver.svg";
export { default as Gold } from "./member-ship/gold.svg";
export { default as Diamond } from "./member-ship/diamond.svg";
export { ReactComponent as ReconIcon } from "./recon.svg";
export { ReactComponent as FacebookIcon } from "./facebook.svg";
export { ReactComponent as InstagramIcon } from "./instagram.svg";
export { ReactComponent as TwitterIcon } from "./twitter.svg";
export { ReactComponent as LinkedlnIcon } from "./linkedln.svg";
export { ReactComponent as SmsIcon } from "./sms.svg";

export { ReactComponent as LogoFull } from "./logo-full.svg";
export { ReactComponent as Notification } from "./notification.svg";
export { ReactComponent as ShoppingCart } from "./shopping-cart.svg";
export { ReactComponent as OutlineStarIcon } from "./outlined-star.svg";
export { ReactComponent as AddCircleIcon } from "./add-circle.svg";
export { ReactComponent as MinusCircleIcon } from "./minus-circle.svg";
export { ReactComponent as MinusCircleIconWarning } from "./minus-circle-warning.svg";
export { ReactComponent as LogoNav } from "./common/logo-menu.svg";
export { ReactComponent as IconMail } from "./common/icon-email.svg";
export { ReactComponent as IconLock } from "./common/icon-lock.svg";
export { ReactComponent as IconStar } from "./common/star.svg";
export { ReactComponent as WhiteArrowLeft } from "./common/white-arrow-left.svg";
export { ReactComponent as WhiteArrorRight } from "./common/white-arrow-right.svg";
export { ReactComponent as WhiteMap } from "./common/white-map.svg";
export { ReactComponent as WhiteChart } from "./common/white-chart.svg";
export { ReactComponent as PinIcon } from "./common/pin-icon.svg";
export { ReactComponent as MapIcon } from "./common/map.svg";
export { ReactComponent as ThreeDot } from "./common/three-dot.svg";
export { ReactComponent as SeafoodIcon } from "./common/seafood-icon.svg";
export { ReactComponent as IconTrash } from "./trash-red-icon.svg";
export { ReactComponent as ArrowUpRight } from "./common/arrow-up-right.svg";
export { ReactComponent as TrackingLocation } from "./location-tracking.svg";
export { default as NoAvatar } from "./common/no-avatar.png";
export { default as NoData } from "./common/no-data.png";
export { default as NoProduct } from "./common/no-product.png";
export { default as NoNote } from "./common/no-note.png";
export { default as NoItem } from "./common/no-item.png";
export { default as EmptyImage } from "../images/empty-image.png";
export { default as DefaultImage } from "../images/default-image.png";
export { default as DefaultVideo } from "../images/default-video.png";
export { default as DefaultPDF } from "../images/default-pdf.png";
export { default as DefaultRestaurant } from "../images/default-restaurant.png";

export { default as AppStoreLogo } from "./common/app-store.svg";
export { default as PlayStoreLogo } from "./common/google-play.svg";
export { default as FilterIcon } from "./common/filter-icon.svg";
export { default as ProductPin } from "./common/product-pin.svg";
export { default as NoteText } from "./common/note-text.svg";
export { default as Chart } from "./common/chart.svg";
export { default as SearchIcon2 } from "./common/search-lg.svg";
export { default as CloseModaIcon } from "./common/close-circle.svg";

export { default as HkDistrict1 } from "./common/hong-kong.svg";
export { default as HkDistrict2 } from "./common/new-territory.svg";
export { default as HkDistrict3 } from "./common/kowloon.svg";

export { ReactComponent as CloseSearchModalIcon } from "./common/close-search-modal-icon.svg";
export { ReactComponent as NoResultsData } from "./common/no-results.svg";

export { ReactComponent as EditIcon } from "./common/edit.svg";
export { ReactComponent as DeleteIcon } from "./common/delete.svg";
export { ReactComponent as ChevronDown } from "./common/chevron-down.svg";
export { ReactComponent as ChevronUp } from "./common/chevron-up.svg";
export { ReactComponent as PlusIcon } from "./common/plus.svg";
export { ReactComponent as MinusIcon } from "./common/minus.svg";
export { ReactComponent as EnglishIcon } from "./common/english-ic.svg";
export { ReactComponent as ChineseIcon } from "./common/chinese-ic.svg";
export { ReactComponent as Tier1UpdateProduct } from "./common/tier1-icon.svg";
export { ReactComponent as Tier2UpdateProduct } from "./common/tier2-icon.svg";
export { ReactComponent as Tier3UpdateProduct } from "./common/tier3-icon.svg";
export { ReactComponent as Tier4UpdateProduct } from "./common/tier4-icon.svg";
export { ReactComponent as Tier5UpdateProduct } from "./common/tier5-icon.svg";

//account settings
export { default as PersonalSetting } from "./common/personal-info-setting.svg";
export { default as PasswordSetting } from "./common/password-setting.svg";
export { default as PaymentSetting } from "./common/payment-setting.svg";
export { default as NotificationSetting } from "./common/notification-setting.svg";
export { default as LanguageSetting } from "./common/language-setting.svg";
export { default as HelpSetting } from "./common/help-setting.svg";
export { default as Deactivate } from "./common/de-activate.svg";
export { default as BusinessInfoSetting } from "./common/business-info-setting.svg";
export { default as BankDetailSetting } from "./common/bank-details-setting.svg";
export { default as LicenseSetting } from "./common/licenses-setting.svg";
export { default as NewsFeedSetting } from "./common/news-feed-setting.svg";
export { default as IntroductionSetting } from "./common/introduction-setting.svg";
export { default as LogisticSetting } from "./common/logistic-setting.svg";

// Menu icons
export { default as MenuCloseIcon } from "./common/btn_exit.svg";
export { ReactComponent as MenuHamburgerIcon } from "./menu/hamburger-icon.svg";
export { ReactComponent as MenuHomeIcon } from "./menu/home-2.svg";
export { ReactComponent as MenuProductIcon } from "./menu/product-category.svg";
export { ReactComponent as MenuQrIcon } from "./menu/scan-barcode.svg";
export { ReactComponent as MenuInternalIcon } from "./menu/data-2.svg";
export { ReactComponent as MenuSubscriptionIcon } from "./menu/notification.svg";
export { ReactComponent as MenuLogisticIcon } from "./menu/logistic.svg";
export { ReactComponent as MenuSearchIcon } from "./menu/search-lg.svg";
export { ReactComponent as MenuScanQrIcon } from "./menu/scan-qr-icon.svg";
export { ReactComponent as MenuLogoutDesktop } from "./menu/logoutDesktop.svg";
export { ReactComponent as MenuLogoutIcon } from "./menu/logout.svg";
export { ReactComponent as MenuFlagIcon } from "./menu/flag.svg";
export { ReactComponent as MenuSettingIcon } from "./menu/setting-2.svg";

export { default as NoFeedBackIcon } from "./common/no-feedback.svg";
export { default as NoFeedBack } from "./common/no-feedback.png";
export { default as ScanImageIcon } from "./common/scan-image-ic.svg";
export { default as ScanCameraIcon } from "./common/scan-camera-ic.svg";
export { default as ScanGenerateIcon } from "./common/scan-generate-ic.svg";
export { ReactComponent as InfoCircle } from "./info-circle.svg";
