import { TIER } from "src/constants/login";
import { ServiceBase } from "./core/service-base";
import { formatQueryParam } from "src/helpers";
interface Params {
  limit?: number;
  page?: number;
  sortBy?: string;
  direction?: string;
  categoryIds?: number[];
}
export class HomepageService extends ServiceBase {
  getHomePageInfo = async (params: Params) => {
    return this.get("/homepage?" + formatQueryParam(params));
  };

  getFeatureProduct = async (params?: Params) => {
    return this.get("/homepage/feature-product", params);
  };

  getNewsFeed = async (params?: Params) => {
    return this.get("/homepage/new-feed", params);
  };

  getIdealRecipe = async (params?: Params) => {
    return this.get("/homepage/ideal-recipe", params);
  };

  getTierList = async (tierType: TIER.FACTORY | TIER.DISTRIBUTOR) => {
    return this.get("/groups/homepage/" + tierType);
  };

  getCatchArea = async () => {
    return this.get("/regions");
  };
}
